import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { PlanContext } from "../Provider/PlanContext";
const AuthorizedRoute = ({ children, title, ...rest }) => {
    const planCtx = useContext(PlanContext);
    // Flattening labels with isActive: true, including those in submodules
    const allLabels = planCtx.features
        .filter((feature) => feature.isActive) // Filter active main features
        .flatMap((feature) => [
            feature.label,
            ...feature.subModules.filter((sub) => sub.isActive).map((sub) => sub.label), // Filter active subModules
        ]);
    console.log(title, "authorizedroute title");
    const isAuthorizedUser = title ? allLabels.includes(title) : false;
    console.log(isAuthorizedUser, "userAuthorized", allLabels);
    return isAuthorizedUser ? children : <Navigate to="/dashboard/home" />;
};

export default AuthorizedRoute;
