import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Card, CardContent, Chip, Skeleton, Grid, Button } from "@mui/material";
import BlacklistPanel from "../AuditReportOverview/BlackListPanel";
import DNSRecords from "../AuditReportOverview/DNSRecords";
import CustomBreadCrumbs from "../common/CustomBreadCrumbs";
import EmailEditor from "../AuditReportOverview/Template";
import AddIntegration from "../AuditReportOverview/AddIntegration";
import SnackBar from "../common/SnackBar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import { styled } from "@mui/system";
const EmailTemplate = ({ recipientDetails, actions, sender, createTemplate, handleRegenerate, regenerate }) => {
    const navigate = useNavigate();
    const workCtx = useContext(WorkspaceContext);
    console.log(sender, recipientDetails === null, recipientDetails, createTemplate, "ifsenderselected");
    const intervalRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);
    const [ipListedZones, setIPListedZones] = useState([]);
    const [domainListedZones, setDomainListedZones] = useState([]);
    const [actualAuditData, setActualAuditData] = useState({});
    const [DNSdata, setDNSData] = useState({});
    const [randomStringForProcess, setRandomStringForProcess] = useState("");
    const [templateList, setTemplateList] = useState([]);
    const [showSenderIntegration, setShowSenderIntegration] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [imapAdded, setImapAdded] = useState(false);
    const progressRef = useRef(progress);
    const processRef = useRef(randomStringForProcess);
    const handleComponentData = async (results) => {
        await Promise.all([handleProcessResult(results.auditResults[0].data), handleTemplate(results.contentAudit)]);
    };
    const handleProcessResult = (results) => {
        const data = results.data;
        console.log(results.data, "result");
        const dnsRecord = data.find((item) => item.title === "DNS Records Check");
        setDNSData(dnsRecord?.data || null);
        const ipReputation = data.find((item) => item.title === "IP Reputation Check");
        setIPListedZones(ipReputation?.data?.ipListedZones?.listed || []);
        const domainReputation = data.find((item) => item.title === "Domain Reputation Check");
        setDomainListedZones(domainReputation?.data?.domainListedZones?.listed || []);
    };
    const handleTemplate = (contentAudit) => {
        console.log(contentAudit, "contentAudittttttttttttttt");
        if (contentAudit && contentAudit.length > 0) {
            const mergedContentList = contentAudit.flatMap((audit) => {
                const contentAuditData = audit.data;

                const originalContent = contentAuditData
                    .filter((item) => item.title === "originalContent")
                    .map((item) => item?.data || null);

                const modifiedContent = contentAuditData
                    .filter((item) => item.title === "modifiedContent")
                    .map((item) => item?.data || null);

                return originalContent.map((content, index) => ({
                    ...content,
                    modifiedContent: modifiedContent[index] || null,
                }));
            });

            setTemplateList(mergedContentList);
        } else {
            setShowSenderIntegration(true);
        }
    };
    const fetchAuditAutomationData = async () => {
        setLoading(true);

        await fetch(`${process.env.REACT_APP_SERVER_URL}/emailLogSinglRecipientReport`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                subject: recipientDetails.subject,
                body: recipientDetails.body,
                sender: recipientDetails.sender,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    handleComponentData(data);
                    setActualAuditData(data);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                setErrorSnackbarOpen(true);
                setErrorMessage("Someting Went Wrong");
            });
    };

    const generateRandomString = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    const handleSenderInteregation = (email) => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/runProcess?sender=${email}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
    };
    const createAuditAutomationData = async () => {
        var randomString;
        randomString = generateRandomString(10);
        setRandomStringForProcess(randomString);
        const url =
            regenerate && !imapAdded
                ? `${process.env.REACT_APP_SERVER_URL}/refreshRegenrateReport`
                : `${process.env.REACT_APP_SERVER_URL}/senderAddedCreateRecord`;
        setLoading(true);
        await fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                subject: recipientDetails.subject,
                body: recipientDetails.body,
                sender: recipientDetails.sender,
                type: "email_log",
                process_code: randomString,
                workspaceId: workCtx.activeWorkspace,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    handleComponentData(data);
                    setActualAuditData(data);
                    if (!regenerate || imapAdded) {
                        handleSenderInteregation(data?.auditResults[0].email || "");
                    }
                    setImapAdded(false);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                    setImapAdded(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setErrorSnackbarOpen(true);
                setErrorMessage("Someting Went Wrong");
            });
    };
    useEffect(() => {
        if (Object.keys(recipientDetails).length > 0) {
            if (createTemplate || regenerate) {
                createAuditAutomationData(true);
            } else {
                fetchAuditAutomationData(false);
            }
        } else {
            setLoading(true);
        }
    }, [recipientDetails]);
    useEffect(() => {
        return () => {
            navigate("/dashboard/spamwatch", { replace: true });
        };
    }, [navigate]);
    const handleImapClose = () => {
        setImapAdded(true);
        createAuditAutomationData();
    };
    const spamWatchURL =
        Object.keys(sender).length > 0
            ? `spamwatch?row=${encodeURIComponent(sender.email)},recipient=${encodeURIComponent(recipientDetails.to)}`
            : `spamwatch?row=undefined,recipient=${encodeURIComponent(recipientDetails.to)}`;
    useEffect(() => {
        if (
            Object.keys(DNSdata).length > 0 &&
            (templateList.length > 0 || showSenderIntegration) &&
            ipListedZones.length > 0 &&
            domainListedZones.length > 0
        ) {
            setLoading(false); // Set loading to false when all data is ready
        }
    }, [DNSdata, templateList, ipListedZones, domainListedZones]);
    useEffect(() => {
        if (randomStringForProcess) {
            const initialTimeout = setTimeout(() => {
                getAuditProgress();

                intervalRef.current = setInterval(() => {
                    getAuditProgress();
                }, 5000);
            }, 10000);

            return () => {
                clearTimeout(initialTimeout);
                clearInterval(intervalRef.current);
            };
        }
    }, [randomStringForProcess]);
    console.log(showSenderIntegration, "showSender");
    const getAuditProgress = async () => {
        try {
            await fetch(
                `${process.env.REACT_APP_SERVER_URL}/fetchProcessResult?process_code=${randomStringForProcess}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success && parseInt(data.data.process_status, 10)) {
                        setProgress(parseInt(data.data.process_status, 10));
                    }

                    if (parseInt(data.data.process_status, 10) >= 100) {
                        clearInterval(intervalRef.current);
                        setProgress(parseInt(data.data.process_status, 10));
                    }
                });
        } catch (error) {
            if (error.message.includes("Failed to fetch")) {
                console.error("Connection refused or network error");

                setErrorMessage("Connection refused or network error");
                setErrorSnackbarOpen(true);
            } else {
                console.error("Error fetching audit progress:", error.message);
            }
        }
    };

    const StripedLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[200],
        "& .MuiLinearProgress-bar": {
            backgroundImage: `repeating-linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.main} 10px, ${theme.palette.primary.light} 10px, ${theme.palette.primary.light} 20px)`,
            animation: "progress-animation 1s linear infinite",
        },
        "@keyframes progress-animation": {
            "0%": {
                backgroundPosition: "0 0",
            },
            "100%": {
                backgroundPosition: "40px 40px",
            },
        },
    }));
    function LinearProgressWithLabel(props) {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ width: "100%", mr: 1 }}>
                    <StripedLinearProgress variant="determinate" {...props} />
                    {/* <LinearProgress
                        variant="determinate"
                        {...props}
                        style={{
                            height: 10, // **Height of the progress bar** (changed)

                            borderRadius: 5, // **Rounded corners** (changed)
                            "& .MuiLinearProgress-bar": {
                                borderRadius: 5, // **Rounded corners of the progress bar fill** (changed)
                            },
                            color: "#fff",
                        }}
                    /> */}
                </Box>
                <Box style={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };
    useEffect(() => {
        progressRef.current = progress;
        processRef.current = randomStringForProcess;
    }, [progress, randomStringForProcess]);
    console.log(loading, "loaddding");
    return (
        <Box>
            <Grid container alignItems="center" justifyContent="space-between">
                {/* Breadcrumbs on the left */}
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <CustomBreadCrumbs actions={actions} />
                    </Grid>{" "}
                    {/* <Grid item>
                        {handleRegenerate && !loading && (
                            <Button variant="contained" color="primary" onClick={handleRegenerate}>
                                Regenerate
                            </Button>
                        )}
                    </Grid> */}
                </Grid>
            </Grid>
            {loading && (createTemplate || regenerate) && (
                <Box style={{ backgroundColor: "#F5F5F5", padding: "20px", borderRadius: "10px" }}>
                    <Box>
                        <Typography>Processing...</Typography>
                    </Box>
                    <LinearProgressWithLabel value={progress} />
                </Box>
            )}
            <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                    {loading ? (
                        <>
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="text" width="50%" height={30} />
                            <Skeleton variant="text" width="40%" height={30} />
                            <Skeleton variant="text" width="70%" height={30} />
                            <Skeleton variant="rectangular" width="80px" height={24} />
                            <Skeleton variant="text" width="30%" height={20} />
                        </>
                    ) : (
                        <>
                            <Typography variant="body1">
                                <strong>Subject:</strong> {recipientDetails?.subject || ""}
                            </Typography>
                            <Typography variant="body1">
                                <strong>From:</strong> {recipientDetails?.sender || ""}
                            </Typography>
                            <Typography variant="body1">
                                <strong>To:</strong> {recipientDetails?.to || ""}
                            </Typography>
                            <Typography variant="body1">
                                <strong>ESP:</strong>{" "}
                                {recipientDetails.esp === "googleEsp"
                                    ? "Google"
                                    : recipientDetails.esp === "outlookEsp"
                                      ? "Outlook"
                                      : "Other"}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Delivery Status:</strong>
                                {recipientDetails.label === "inbox" ? (
                                    <Chip label="Inbox" color="success" size="small" />
                                ) : (
                                    <Chip label="Spam" color="error" size="small" />
                                )}
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: 1, color: "gray" }}>
                                {new Date(recipientDetails?.createdAt || "")
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, "-")}
                            </Typography>
                        </>
                    )}
                </CardContent>
            </Card>
            <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ marginBottom: 2 }} fontWeight="bold">
                        Template
                    </Typography>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={150} />
                    ) : (
                        <Box>
                            {showSenderIntegration ? (
                                <AddIntegration
                                    handleImapClose={handleImapClose}
                                    selectedButton={spamWatchURL}
                                    senderEmail={recipientDetails.sender}
                                />
                            ) : (
                                <EmailEditor templates={templateList} actualTemplate={actualAuditData.contentAudit} />
                            )}
                        </Box>
                    )}
                </CardContent>
            </Card>
            <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ marginBottom: 2 }} fontWeight="bold">
                        Domain Analytics
                    </Typography>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={200} />
                    ) : (
                        <DNSRecords dnsRecords={DNSdata} />
                    )}
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant="h6" sx={{ marginBottom: 2 }} fontWeight="bold">
                        Blacklist
                    </Typography>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={200} />
                    ) : (
                        <BlacklistPanel domainListedZones={domainListedZones} ipListedZones={ipListedZones} />
                    )}
                </CardContent>
            </Card>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};

export default EmailTemplate;
