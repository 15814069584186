//import { useEffect, useState } from "react";

import { useContext, useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HomeIcon from "@mui/icons-material/Home";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import SnackBar from "../../common/SnackBar";
const Connected = ({ handleAuditCampaign, connectedDetails, handleBack }) => {
    const workCtx = useContext(WorkspaceContext);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const handleDisconnect = () => {
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/mailchimp/disconnect?id=${connectedDetails.id}&workspace_id=${workCtx.activeWorkspace}`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(data.message);
                    setTimeout(() => handleBack(), 3000);
                }
            });
    };
    return (
        <Box>
            <IconButton disableRipple sx={{ fontSize: 80 }} onClick={handleAuditCampaign}>
                <HomeIcon />
            </IconButton>
            <Box
                sx={{
                    maxWidth: 800,
                    margin: "auto",
                    padding: "20px",
                    textAlign: "center",
                }}
            >
                <IconButton disableRipple sx={{ fontSize: 80, color: "black" }}>
                    <MailOutlineIcon fontSize="inherit" />
                </IconButton>
                <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                    Connect Mailchimp Marketing Platform
                </Typography>
                <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                    Easily audit campaigns from mailchimp marketing platforms by connecting your tools.
                </Typography>

                <Button
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, textTransform: "none", px: 4 }}
                    onClick={handleDisconnect}
                >
                    Disconnect
                </Button>
            </Box>
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </Box>
    );
};

export default Connected;
