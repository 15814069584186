import React, { useEffect, useState } from "react";
import {
    Box,
    Paper,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    InputBase,
    Menu,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { tableCellClasses } from "@mui/material/TableCell";
import { TableRow, TableCell, CircularProgress, TableContainer, TableHead, TableBody, Table } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Skeleton, Pagination } from "@mui/material";
import SnackBar from "../common/SnackBar";
import Step2 from "./Step2";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },

    "&:hover": {
        backgroundColor: "#f1f1f1 !important",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const DomainName = () => {
    const navigate = useNavigate();
    const [domain, setDomain] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteIpDialougeOpen, setdDeleteIpDialougeOpen] = React.useState(false);
    const [Loader, setLoader] = useState(false);
    const [deleteId, setdeleteId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [userInput, setUserInput] = useState("");
    const [filterInputData, setFilterInputData] = React.useState("All");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showStep2, setShowStep2] = useState({
        show: false,
        txt: "",
        domain: "",
    });
    const [page, setPage] = useState(1);
    const [actionMenu, setActionMenu] = useState(null); // Store anchor element
    const [openAction, setOpenAction] = useState(false); // Manage menu state
    const [selectedRow, setSelectedRow] = useState(null);
    const handleActionMenu = (event, data) => {
        setActionMenu(event.currentTarget);
        setOpenAction(true);
        setSelectedRow(data);
    };
    const handleActionClose = () => {
        setActionMenu(null);
        setOpenAction(false);
        setSelectedRow(null);
    };
    const rowsPerPage = 10;
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        handleDomainList();
    }, []);

    function handleDomainClick(domain) {
        if (domain.status === "Verified") {
            navigate(`/dashboard/DomainReputation/features/${domain.domain}/${domain.businessName}`);
        } else {
            console.log("%cNot verified", "color: red");
        }
    }

    function formatDate(inputDate) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

        const parts = inputDate.split("-");
        if (parts.length === 3) {
            const day = parseInt(parts[0], 10);
            const monthIndex = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);

            if (!isNaN(day) && !isNaN(monthIndex) && !isNaN(year)) {
                if (monthIndex >= 0 && monthIndex < months.length) {
                    const monthName = months[monthIndex];
                    return `${day} ${monthName} ${year}`;
                }
            }
        }

        return "Invalid Date";
    }
    const activeWorkspace = localStorage.getItem("activeWorkspace");
    function DeleteIpHandle(e) {
        handleActionClose();
        let domain = e;
        setLoader(true);
        fetch(`${REACT_APP_SERVER_URL}/DeleteDomainRep`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                domain: domain,
                workSpaceId: activeWorkspace,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoader(false);
                if (data.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Domain Deleted Successfully");
                    handleDomainList();
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Internal server error");
                }
            });
    }

    function inputHanlder(e) {
        let input = e.target.value.toLowerCase();
        setUserInput(input);
    }

    const filterData = domain.filter((d) => {
        const userInputLower = userInput.toLowerCase();
        const domainLower = d?.domain?.toLowerCase() || "";
        const businessNameLower = d?.businessName?.toLowerCase() || "";
        const statusLower = d?.status?.toLowerCase() || "";
        if (userInput !== "") {
            return (
                domainLower.includes(userInputLower) ||
                businessNameLower.includes(userInputLower) ||
                statusLower.includes(userInputLower) ||
                formatDate(d.create_time).toLowerCase().includes(userInputLower)
            );
        } else {
            if (filterInputData === "All") {
                return true;
            }
            if (filterInputData === "Verified") {
                return d.status === "Verified";
            }
            if (filterInputData === "Unverified") {
                return d.status !== "Verified";
            }
            if (filterInputData === "Date" && selectedDate) {
                const domainDate = dayjs(d.create_time, "D-M-YYYY");
                return domainDate.isValid() && domainDate.isSame(selectedDate, "day");
            }
        }
    });

    const handleChange = (e) => {
        setFilterInputData(e.target.value);
    };

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        setFilterInputData("Date");
    };

    async function handleDomainList() {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/domainslist?workSpaceId=${activeWorkspace}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                // setLoading(false);
                return response.json();
            })
            .then(async (data) => {
                if (data.success || data.status === 200) {
                    if (data.data.length > 0) {
                        data.data.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
                    }

                    setDomain(data.data);
                    setLoading(false);
                    // }
                } else {
                    setDomain([]);
                    console.log(data.message, "Error Fetching domains");
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    }

    const verifyDomain = (txt, domain) => {
        handleActionClose();
        console.log(txt);
        setShowStep2({
            show: true,
            txt,
            domain,
        });
    };
    const onCloseVerification = () => {
        setShowStep2({ show: false, txt: "", domain: "" });
        handleActionClose();
        handleDomainList();
    };

    return (
        <div className="container">
            {Loader ? (
                <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
                    <CircularProgress />
                    <Typography>Deleting...</Typography>
                </Box>
            ) : (
                <div>
                    <Dialog
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        onClose={() => {
                            setdDeleteIpDialougeOpen(false);
                            setdeleteId("");
                        }}
                        open={deleteIpDialougeOpen}
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete account?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    DeleteIpHandle(deleteId);
                                    setdDeleteIpDialougeOpen(false);
                                }}
                                variant="contained"
                                color="success"
                            >
                                Delete
                            </Button>
                            <Button
                                onClick={() => {
                                    setdDeleteIpDialougeOpen(false);
                                    setdeleteId("");
                                }}
                                variant="outlined"
                                color="error"
                                disabled={false}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Box>
                        <>
                            <Box>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Search Domain & Client Name"
                                        onChange={inputHanlder}
                                        InputProps={{
                                            startAdornment: (
                                                <SearchIcon style={{ color: "#aaa", marginRight: "8px" }} />
                                            ),
                                        }}
                                        style={{
                                            width: "50%",
                                            margin: "10px 10px 10px 0px",
                                            float: "left",
                                        }}
                                    />
                                    <FormControl sx={{ width: "50%", height: "50px", marginTop: "9px" }}>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterInputData}
                                            label="Status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={"All"}>All</MenuItem>
                                            <MenuItem value={"Verified"}>Verified</MenuItem>
                                            <MenuItem value={"Unverified"}>Unverified</MenuItem>
                                            <MenuItem value={"Date"}>Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {filterInputData === "Date" && (
                                        <Box style={{ marginLeft: "5px", marginTop: "9px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select Date" fullWidth />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    )}
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Domain Name</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell>Reputation</StyledTableCell>
                                                <StyledTableCell>Business Name</StyledTableCell>
                                                <StyledTableCell>Added on</StyledTableCell>
                                                <StyledTableCell>Actions</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        {loading ? (
                                            <TableBody>
                                                {[...Array(5)].map((_, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="rectangular" width={30} height={30} />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <>
                                                <TableBody>
                                                    {filterData.length > 0 ? (
                                                        filterData
                                                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                                            .map((data) => (
                                                                <TableRow
                                                                    key={data.domain}
                                                                    sx={{
                                                                        "&:hover": {
                                                                            backgroundColor: "#f0f0f0",
                                                                        },
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (data.status === "Verified") {
                                                                            handleDomainClick(data);
                                                                        } else {
                                                                            verifyDomain(data.txtRecord, data.domain);
                                                                        }
                                                                    }}
                                                                >
                                                                    <StyledTableCell component="th" scope="row">
                                                                        {data.domain}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            color:
                                                                                data.status === "Verified"
                                                                                    ? "green"
                                                                                    : "orange", // Green for verified, orange for pending
                                                                        }}
                                                                    >
                                                                        {data.status === "Verified"
                                                                            ? "verified"
                                                                            : "pending"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        {data.status !== "Verified"
                                                                            ? "N/A"
                                                                            : data?.domainReputation
                                                                              ? data?.domainReputation
                                                                              : "N/A"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        {data.businessName || "NA"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    lineHeight: "18px",
                                                                                }}
                                                                            >
                                                                                {formatDate(data.create_time)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <MoreVertIcon
                                                                            id="basic-button"
                                                                            aria-controls={
                                                                                openAction &&
                                                                                selectedRow?.domain === data.domain
                                                                                    ? "basic-menu"
                                                                                    : undefined
                                                                            }
                                                                            aria-haspopup="true"
                                                                            aria-expanded={
                                                                                openAction &&
                                                                                selectedRow?.domain === data.domain
                                                                                    ? "true"
                                                                                    : undefined
                                                                            }
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                handleActionMenu(event, data);
                                                                            }}
                                                                            sx={{
                                                                                cursor: "pointer",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        />
                                                                        {selectedRow?.domain === data.domain && (
                                                                            <Menu
                                                                                id="basic-menu"
                                                                                anchorEl={actionMenu}
                                                                                open={openAction}
                                                                                onClose={handleActionClose}
                                                                                MenuListProps={{
                                                                                    "aria-labelledby": "basic-button",
                                                                                }}
                                                                            >
                                                                                <MenuItem
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleActionClose();
                                                                                        setdDeleteIpDialougeOpen(true);
                                                                                        setdeleteId(data.domain);
                                                                                    }}
                                                                                    sx={{
                                                                                        cursor: "pointer",
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    Delete
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    sx={{
                                                                                        cursor: "pointer",
                                                                                        color: "green",
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if (
                                                                                            data.status === "Verified"
                                                                                        ) {
                                                                                            handleDomainClick(data);
                                                                                        } else {
                                                                                            verifyDomain(
                                                                                                data.txtRecord,
                                                                                                data.domain
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {data.status !== "Verified"
                                                                                        ? "Verify"
                                                                                        : "View Report"}
                                                                                </MenuItem>
                                                                            </Menu>
                                                                        )}
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                            ))
                                                    ) : (
                                                        <TableRow>
                                                            <StyledTableCell component="th" scope="row" colSpan={4}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "16px",
                                                                        fontWeight: 500,
                                                                        lineHeight: "18px",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    No Data Found
                                                                </Typography>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </>
                                        )}
                                    </Table>
                                </TableContainer>
                                {filterData.length > 10 && (
                                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                                        <Pagination
                                            count={Math.ceil(filterData.length / rowsPerPage)}
                                            page={page}
                                            onChange={handleChangePage}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    </Box>
                </div>
            )}
            {showStep2.show && (
                <Step2
                    onPrevious={onCloseVerification}
                    onClose={onCloseVerification}
                    domain={showStep2.domain}
                    txt={showStep2.txt}
                />
            )}
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </div>
    );
};

export default DomainName;
