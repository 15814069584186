import { useContext, useEffect, useState } from "react";
import AddSender from "./AddSender";
import { Box } from "@mui/material";
import { WorkspaceContext } from "../../../Provider/WorkspaceProvider";
import AuditForm from "./AuditForm";
import { CircularProgress } from "@mui/material";
import CreateReport from "../../../AuditReportOverview/CreateCampaignReport";
const TestAudit = ({ secondParam, handleBack, rows, selectedRow, showPlatformCampaignList }) => {
    const workctx = useContext(WorkspaceContext);
    const [openAuditForm, setOpenAuditForm] = useState(false);
    const [senderList, setSenderList] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [viewReport, setViewReport] = useState(false);
    const [loader, setLoader] = useState(true);
    const [key, setKey] = useState(0);
    const [regenerate, setRegenerate] = useState(false);
    const [sender, setSender] = useState("");
    const [processCode, setProcessCode] = useState("");
    const fetchSenderList = () => {
        setLoader(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSenderList?workSpaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSenderList(data.data);
                    setOpenAuditForm(true);
                    setViewReport(false);
                } else {
                    setOpenAuditForm(false);
                    setViewReport(false);
                    setLoader(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };
    useEffect(() => {
        fetchSenderList();
    }, []);
    const onSubmit = (data) => {
        setOpenAuditForm(false);
        setClientData(data);
        setViewReport(true);
        console.log(data, "formmmdata");
    };
    const handleImapClose = () => {
        setOpenAuditForm(false);
        setViewReport(false);
        fetchSenderList();
    };
    useEffect(() => {
        if (senderList.length > 0) {
            console.log("i am working");
            setLoader(false);
        }
    }, [senderList]);
    if (loader) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }
    const handleRegenerate = (sender, code) => {
        setSender(sender);
        setProcessCode(code);
        console.log("regenrateclicked");
        setRegenerate(true);
        setKey((prev) => prev + 1);
    };
    return (
        <Box key={key}>
            <Box>
                {(viewReport || regenerate) && (
                    <CreateReport
                        clientData={clientData}
                        handleBackReport={handleBack}
                        actions={[
                            { title: "Campaign List", onClick: showPlatformCampaignList },
                            { title: "Report", onClick: null },
                        ]}
                        regenerate={regenerate}
                        handleRegenerate={(sender, process_code) => handleRegenerate(sender, process_code)}
                        sender={sender}
                        type="mail_chimp"
                        processCode={processCode}
                    />
                )}
                {openAuditForm && !viewReport && !loader && (
                    <AuditForm
                        secondParam={secondParam}
                        senderList={senderList}
                        handleBack={handleBack}
                        onSubmit={(e) => onSubmit(e)}
                        handleImapClose={handleImapClose}
                        rows={rows}
                        selectedRow={selectedRow}
                    />
                )}
                {!openAuditForm && !viewReport && !loader && (
                    <AddSender
                        handleBack={handleBack}
                        handleImapClose={handleImapClose}
                        secondParam={secondParam ? secondParam : selectedRow.id}
                    />
                )}
            </Box>
        </Box>
    );
};
export default TestAudit;
