import React, { useContext, useEffect } from "react";
import { Box, Button, Typography, Container, Paper } from "@mui/material";
import XAuditLogo from "../../img/icons/xaudit1.png";
import { useLocation, useNavigate } from "react-router-dom";
import cookie from "cookiejs";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
const EmailVerified = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const workspaceCtx = useContext(WorkspaceContext);
    useEffect(() => {
        if (!cookie.get("token")) {
            navigate("/login");
        }
        if (state?.user?.data) {
            workspaceCtx.handleworkspaceLimit({
                maxTeam: state.user.data.maxTeam,
                maxWorkspace: state.user.data.maxWorkspace,
            });
        }
    }, []);

    return (
        <Box>
            <Box
                component="img"
                src={XAuditLogo}
                alt="Placeholder"
                sx={{ marginBottom: 2, width: "15%", height: "auto" }}
            />
            <Container maxWidth="md" sx={{ marginTop: "30px" }}>
                <Box style={{ padding: "30px", textAlign: "center" }}>
                    <Typography sx={{ fontSize: "30px", fontWeight: "700", textAlign: "center" }}>
                        Welcome to XemailAudit
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Your email has been successfully verified, and your account is now active!
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        You're all set to explore XemailAudit and take your email campaigns to the next level.
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ marginTop: "20px" }}
                        onClick={() => navigate("/workspace", { state: { user: state.user.data } })}
                    >
                        Get Started
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default EmailVerified;
