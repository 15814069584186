import React, { useState, useEffect } from "react";
import { Box, Typography, Button, ButtonGroup, TextField } from "@mui/material";
import SnackBar from "../../common/SnackBar";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ReactComponent as Refresh } from "./refresh.svg";
import CreateReport from "../../AuditReportOverview/CreateCampaignReport";
import CustomBreadCrumbs from "../../common/CustomBreadCrumbs";
export default function EmailAccountForm({ handleBackReport, actions = [], rows }) {
    //     const emailList = `IPT@MYLEADS.COM,LINDA@MEETXLEADS.COM,IPT@PROXLEADS.ORG,NIHARIKA@ZGXEMAILWARMUP.COM, AGARWAL@XLEADSCLOUD.ORG,
    //   ANURAG.JOHN0003@GMAIL.COM,V7k4578B@GMAIL.COM,SHIVANIRAMSAGAR807@GMAIL.COM, BRIJESHITESTING12345@GMAIL.COM,BRIJESHITESTING1234@GMAIL.COM,
    //   XGROWTH11@GOXEMAILDELIVER.COM,TESTING@EMAILDELIVERYREPORT.NET, XGROWTH1@XLEADSCONSULTING.COM,PATRICIA@XGROWTH1HQ.CO,PATRICIA@XLEADSHQ.COM,LINDA.P@XLEADSHQ.CO,
    //   NIHARIKA.JG@XGROWTH1HQ.ORG,AGARWAL.NK@XEMAILDELIVERHQ.CO`;
    // const emailList = `testing@emaildeliveryreport.net, ipt@myxleads.com, linda@meetxleads.com, ipt@proxleads.org, niharika@b2cxemailwarmup.com, agarwal@xleadscloud.org, anurag.johri003@gmail.com, v76457188@gmail.com, shivaniramsagar807@gmail.com, brijeshtesting12345@gmail.com, brijeshtesting1234@gmail.com, riasharma2024@outlook.com, anjalisharma19861@outlook.com, anjujain1911@outlook.com, amarasharma2024@outlook.com, aryajain2024@outlook.com, patricia@xgrowthhq.co, patricia@xleadshq.com
    // `;
    // const emailList = `testing@emaildeliveryreport.net, ipt@myxleads.com, linda@meetxleads.com, ipt@proxleads.org, niharika@b2cxemailwarmup.com`;
    const emailList = `anurag.johri003@gmail.com, v76457188@gmail.com, shivaniramsagar807@gmail.com, brijeshtesting12345@gmail.com, brijeshtesting1234@gmail.com, riasharma2024@outlook.com, anjalisharma19861@outlook.com, anjujain1911@outlook.com, amarasharma2024@outlook.com, aryajain2024@outlook.com`;
    const [campaignName, setCampaignName] = useState("");
    const [code, setCode] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [viewReport, setViewReport] = useState(false);
    const [touched, setTouched] = useState(false);
    const [regenerate, setRegenerate] = useState(false);
    const [key, setKey] = useState(false);
    const [sender, setSender] = useState("");
    const ranString = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "XGW-";
        for (let i = 0; i < 12; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCode(result);
    };
    function refresh() {
        ranString();
    }

    useEffect(() => {
        ranString();
    }, []);
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setSnackbarMessage("Copied to clipboard!");
        setSnackbarOpen(true);
    };
    const handleViewReport = () => {
        setViewReport(true);
    };
    const handleCampaignName = (value) => {
        setCampaignName(value);
    };
    const handleBlur = () => {
        setTouched(true); // Mark as touched when input loses focus
    };

    const isError =
        touched && campaignName.trim().length < 1
            ? "Campaign name cannot be empty"
            : rows.find((data) => data.campaign_name === campaignName)
              ? "Campaign name already exists"
              : "";

    console.log(campaignName, "campaignName", isError);
    const handleRegenerate = (sender) => {
        console.log(sender, "regenrateclicked");
        setSender(sender);
        setRegenerate(true);
        setKey((prev) => prev + 1);
    };
    const isDisabled = campaignName.trim().length < 1 || rows.some((data) => data.campaign_name === campaignName);
    return (
        <Box key={key}>
            {viewReport ? (
                <CreateReport
                    regenerate={regenerate}
                    type="manual_audit"
                    clientData={{ process_code: code, emailList: emailList, campaign_name: campaignName }}
                    selectedButton={`email-audit-v1?tab=email,campaign=${campaignName}`}
                    handleBackReport={handleBackReport}
                    actions={[
                        { title: "Email Accounts", onClick: handleBackReport },
                        { title: "Report", onClick: null },
                    ]}
                    handleRegenerate={(sender) => handleRegenerate(sender)}
                    sender={sender}
                />
            ) : (
                <Box sx={{ mt: 4 }}>
                    <CustomBreadCrumbs actions={actions} />
                    <Box>
                        <section className="card">
                            <Box>
                                <p
                                    style={{
                                        textAlign: "center",
                                        fontSize: "17px",
                                        color: "rgba(71, 84, 103, 1)",
                                        fontWeight: "400px",
                                        lineHeight: "20px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Manually send a test email to discover its destination. Just follow the provided
                                    instructions.
                                </p>
                                <Box display="flex" alignItems="center" gap={2} justifyContent="center" sx={{ mb: 2 }}>
                                    <Typography variant="subtitle1" component="label" style={{ fontWeight: "bold" }}>
                                        Step 1:
                                    </Typography>
                                    <TextField
                                        placeholder="Enter Campaign Name"
                                        fullWidth
                                        size="small"
                                        value={campaignName}
                                        style={{ maxWidth: "300px" }}
                                        onChange={(e) => handleCampaignName(e.target.value)}
                                        onBlur={handleBlur}
                                        error={!!isError} // Convert the string to boolean
                                        helperText={isError || ""} // Display the error message
                                    />
                                </Box>
                                <Box display="flex" alignItems="center" gap={2} justifyContent="center" sx={{ mb: 2 }}>
                                    <Typography variant="subtitle1" component="label" sx={{ fontWeight: "bold" }}>
                                        Step 2:
                                    </Typography>
                                    <ButtonGroup
                                        style={{
                                            borderRadius: "8px",
                                        }}
                                        disableElevation
                                        aria-label="Disabled elevation buttons"
                                        onClick={(e) => {
                                            handleCopy(code);
                                            setSnackbarOpen(true);
                                            setSnackbarMessage("Code copied Successfully");
                                        }}
                                    >
                                        <Button
                                            onClick={refresh}
                                            sx={{
                                                height: "3.4em",
                                                backgroundColor: "white",
                                                border: "1 px solid black",
                                            }}
                                        >
                                            <Refresh></Refresh>
                                        </Button>
                                        <Button
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                fontSize: "20px",
                                                // padding: "4px",
                                            }}
                                        >
                                            {code}
                                        </Button>
                                        <Button sx={{ background: "white", color: "black", padding: "4px" }}>
                                            <FileCopyIcon></FileCopyIcon>
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                                <Typography
                                    variant="subtitle1"
                                    component="label"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "17px",
                                        color: "rgba(71, 84, 103, 1)",
                                        lineHeight: "20px",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "rgba(255, 115, 115, 0.93)",
                                            fontWeight: 600,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        NOTE:
                                    </span>{" "}
                                    Add this code into your email subject line.
                                </Typography>
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                                <Typography
                                    style={{
                                        fontWeight: "bold",
                                        lineHeight: "38px",
                                        textAlign: "center",
                                    }}
                                >
                                    Step 3: Send your email to the provided email addresses
                                </Typography>
                                <ButtonGroup
                                    disableElevation
                                    aria-label="Disabled elevation buttons"
                                    sx={{
                                        borderRadius: "8px",
                                    }}
                                    onClick={(e) => {
                                        handleCopy(emailList);
                                        setSnackbarOpen(true);
                                        setSnackbarMessage("Addresses copied Successfully");
                                    }}
                                >
                                    <Button sx={{ background: "white", color: "black", fontSize: "12px" }}>
                                        {emailList}
                                    </Button>
                                    <Button sx={{ background: "white", color: "black" }}>
                                        <FileCopyIcon></FileCopyIcon>
                                    </Button>
                                </ButtonGroup>
                            </Box>

                            <Box>
                                <Typography
                                    sx={{
                                        marginTop: "8px",
                                        fontSize: "17px",
                                        color: "rgba(71, 84, 103, 1)",
                                        lineHeight: "20px",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "rgba(255, 115, 115, 0.93)",
                                            fontWeight: 600,
                                            lineHeight: "20px",
                                        }}
                                    >
                                        NOTE:
                                    </span>{" "}
                                    This code will only search for results within 4 minutes of sending the email. After
                                    that, the code will expire, and it will show the latest results. Please send the
                                    code and email first, then proceed to search for the result.
                                </Typography>
                            </Box>
                        </section>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                        <Button variant="contained" color="primary" onClick={handleViewReport} disabled={isDisabled}>
                            View Report
                        </Button>
                    </Box>
                    <SnackBar
                        type="success"
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={snackbarMessage}
                    />
                </Box>
            )}
        </Box>
    );
}
