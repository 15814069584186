import { useContext, useEffect, useState } from "react";
import CustomCard from "../common/CustomCard";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import Instantly from "../../img/images/instant.png";
import SmartLead from "../../img/images/smartlead.png";
import CustomBcc from "../../img/images/inbox.png";
import ConnectInstantly from "./InstanlyConnect";
import SmartLeadAPIForm from "./SmartLead";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import SnackBar from "../common/SnackBar";
import Skeleton from "@mui/material/Skeleton";
import CustomAlertDialog from "../common/CustomAlertDialog";
import { PlanContext } from "../Provider/PlanContext";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CustomBccConnect from "./CustomBccConnect";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
        },
    })
);
const Tools = () => {
    const workctx = useContext(WorkspaceContext);
    const planCtx = useContext(PlanContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showInstantly, setShowInstantly] = useState(false);
    const [showSmartLead, setShowSmartLead] = useState(false);
    const [showCustomBcc, setShowCustomBcc] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [disconnectObj, setDisconnectObj] = useState({});
    const integrationSubModules =
        planCtx.features.find((feature) => feature.label === "Integrations")?.subModules ?? [];
    console.log(integrationSubModules, "integrationsub");

    const thirdPartyDomain = [
        // {
        //     title: "Custom",
        //     img: CustomBcc,
        //     path: "Custom",
        //     imgTitle: "BCC",
        //     isConnected: false,
        //     isActive: true,
        // },
        {
            title: "Custom",
            img: CustomBcc,
            path: "Custom",
            imgTitle: "BCC",
            isConnected: false,
            isActive: true,
        },
        {
            title: "Instantly.ai",
            img: Instantly,
            imgTitle: "Instantly",
            path: "instantly",
            isConnected: false,
        },
        {
            title: "Smartlead.ai",
            img: SmartLead,
            path: "smartlead",
            isConnected: false,
        },
    ];
    const updatedThirdPartyDomain = thirdPartyDomain.map((domain) => {
        const matchingSubModule = integrationSubModules?.find((sub) => sub.label === domain.title);
        return {
            ...domain,
            isActive: domain.isActive || (matchingSubModule ? matchingSubModule.isActive : false), // Default to true if `domain.isActive` is true
        };
    });
    console.log(updatedThirdPartyDomain, "updated third");
    const [domainsIntegration, setDomainIntegration] = useState(updatedThirdPartyDomain);
    const fetchIntegrationApp = () => {
        setShowSkeleton(true);
        fetch(`${REACT_APP_SERVER_URL}/fetchIntegrationApp/${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                handleIntegration(data.data);
            })
            .catch((error) => {
                console.error("Fetch error: ", error);
                setErrorSnackbarOpen(true);
                setErrorMessage("Failed to fetch integrations.");
                setShowSkeleton(false);
            });
    };
    useEffect(() => {
        fetchIntegrationApp();
    }, [workctx.activeWorkspace]);
    const handleIntegration = (data) => {
        const updateIntegration = JSON.parse(JSON.stringify(domainsIntegration));
        if (!data) {
            updateIntegration.forEach((domain) => {
                domain.isConnected = false;
                delete domain.id;
            });
        } else {
            updateIntegration.forEach((domain) => {
                const match = data.find((item) => item.name.includes(domain.path));
                if (match) {
                    domain.isConnected = true;
                    domain.id = match.id;
                } else {
                    domain.isConnected = false;
                    delete domain.id;
                }
            });
        }

        setDomainIntegration(updateIntegration);
        setShowSkeleton(false);
    };
    const handleDisconnect = (obj) => {
        setIsLoading(obj);
        fetch(`${REACT_APP_SERVER_URL}/diconnectIntegratedApp`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspaceId: workctx.activeWorkspace,
                integratedId: obj.id,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    fetchIntegrationApp();
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(data.message || data.msg);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                }
            })
            .catch((error) => {
                console.error("Disconnect error: ", error);
                setErrorSnackbarOpen(true);
                setErrorMessage("Failed to disconnect.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const handleClick = (obj) => {
        if (obj.isConnected) {
            setShowAlert(true);
            setDisconnectObj(obj);
            // handleDisconnect(obj);
        } else {
            if (obj.path === "instantly") {
                setShowInstantly(true);
            }
            if (obj.path === "smartlead") {
                setShowSmartLead(true);
            }
            if (obj.path === "Custom") {
                setShowCustomBcc(true);
            }
        }
    };
    console.log(domainsIntegration, "domains");
    return (
        <>
            <Box>
                {(showInstantly || showSmartLead || showCustomBcc) && (
                    <Button
                        sx={{ margin: "15px" }}
                        onClick={() => {
                            fetchIntegrationApp();
                            setShowInstantly(false);
                            setShowSmartLead(false);
                            setShowCustomBcc(false);
                        }}
                    >
                        <ArrowBackIosIcon />
                        Back
                    </Button>
                )}
                {showSmartLead && (
                    <SmartLeadAPIForm
                        handleRedirect={() => {
                            fetchIntegrationApp();
                            setShowInstantly(false);
                            setShowSmartLead(false);
                            setShowCustomBcc(false);
                            setSuccessSnackbarOpen(true);
                            setSuccessMessage("SmartLead Connected");
                        }}
                    />
                )}
                {showCustomBcc && (
                    <CustomBccConnect
                        handleRedirect={() => {
                            fetchIntegrationApp();
                            setShowInstantly(false);
                            setShowSmartLead(false);
                            setShowCustomBcc(false);
                            setSuccessSnackbarOpen(true);
                            setSuccessMessage("Custom Bcc Connected");
                        }}
                    />
                )}
                {showInstantly && (
                    <ConnectInstantly
                        handleRedirect={() => {
                            fetchIntegrationApp();
                            setShowInstantly(false);
                            setShowSmartLead(false);
                            setShowCustomBcc(false);
                            setSuccessSnackbarOpen(true);
                            setSuccessMessage("Instantly Connected");
                        }}
                    />
                )}
                {!showInstantly && !showSmartLead && !showCustomBcc && (
                    <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {showSkeleton ? (
                            <>
                                {updatedThirdPartyDomain.map((_, index) => (
                                    <Grid item xs={2} sm={2} md={4} key={index}>
                                        <Skeleton
                                            variant="rectangular"
                                            width={300}
                                            height={200}
                                            animation="wave"
                                            style={{ margin: "10px 0" }}
                                        />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton width="60%" />
                                            <Skeleton width="80%" />
                                        </Box>
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                {domainsIntegration.map((arr, index) => (
                                    <LightTooltip
                                        title={!arr.isActive ? "Subscribe to Access!" : ""}
                                        placement="bottom"
                                        key={index}
                                    >
                                        <Grid item xs={2} sm={2} md={4} key={index}>
                                            <CustomCard
                                                title={arr.title}
                                                img={arr.img}
                                                style={arr.title === "Smartlead.ai" ? { width: 122, height: 40 } : null}
                                                imgTitle={arr.imgTitle}
                                                isLoading={isLoading}
                                                disabled={!arr.isActive}
                                                actions={[
                                                    {
                                                        text: arr.isConnected ? "Disconnect" : "Connect",
                                                        type: arr.isConnected ? "secondary" : "success",
                                                        handleClick: () => handleClick(arr),
                                                    },
                                                ]}
                                                badgeText={arr.isConnected ? "Connected" : arr.isConnected}
                                            />
                                        </Grid>
                                    </LightTooltip>
                                ))}
                            </>
                        )}
                    </Grid>
                )}
            </Box>
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
            {showAlert && (
                <CustomAlertDialog
                    open={showAlert}
                    handleClose={() => {
                        setShowAlert(false);
                        setDisconnectObj({});
                    }}
                    content={"Are you sure you want to disconnect?"}
                    handleAlert={() => {
                        setShowAlert(false);
                        handleDisconnect(disconnectObj);
                    }}
                />
            )}
        </>
    );
};
export default Tools;
