import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TemplateList from "../NewTemplateList";
import AddIntegration from "../AddIntegration";
import SkeletonLoader from "../SeketonLoader";
import { Box, Grid, Card, CardContent, Typography, Avatar, Button } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DNSOverviewChart from "../DNSOverviewChart";
import ActionComponent from "../ActionComponent";
import BlacklistPanel from "../BlackListPanel";
import DNSRecords from "../DNSRecords";
import CustomBreadCrumbs from "../../common/CustomBreadCrumbs";
import ESP from "../ESPDetails";
import SnackBar from "../../common/SnackBar";
import DownloadReport from "../DownloadReport";
import AuditShare from "../AuditShare";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import EmailEditor from "../Template";
const Report = ({ selectedButton, actions = [], reportData, handleBack, handleRegenerate }) => {
    const workCtx = useContext(WorkspaceContext);
    const [dnsData, setDNSData] = useState([]);
    const [ipListedZones, setIPListedZones] = useState([]);
    const [domainListedZones, setDomainListedZones] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [selectedPanel, setSelectedPanel] = useState("Overview");
    const [iptCounts, setIptCounts] = useState({});
    const [actualAuditData, setActualAuditData] = useState({});
    const [score, setScore] = useState(null);
    const [overview, setOverview] = useState([]);
    const [loader, setLoader] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSenderIntegration, setShowSenderIntegration] = useState(false);
    const [auditSender, setAuditSender] = useState("");

    const navigate = useNavigate();
    const handlePanelSelect = (panel) => {
        setSelectedPanel(panel);
    };

    const handleComponentData = async (results) => {
        console.log(results, results.contentAudit, "resultttttttttttttt");
        setAuditSender(results?.auditResults[0].email || "");
        await Promise.all([
            handleProcessResult(results.auditResults[0].data, results.scores),
            handleTemplate(results.contentAudit),
            handleIptCount(results.iptCounts.totals),
        ]);
    };
    const handleProcessResult = (results, scores) => {
        const data = results.data;
        console.log(results, "result");
        setScore(Math.floor(scores?.totalScore) || 0);
        const dnsRecord = data.find((item) => item.title === "DNS Records Check");
        setDNSData(dnsRecord?.data || null);
        const ipReputation = data.find((item) => item.title === "IP Reputation Check");
        setIPListedZones(ipReputation?.data?.ipListedZones?.listed || []);
        const domainReputation = data.find((item) => item.title === "Domain Reputation Check");
        setDomainListedZones(domainReputation?.data?.domainListedZones?.listed || []);
    };
    const handleTemplate = (contentAudit) => {
        console.log(contentAudit, "contenttttttttttt");
        if (contentAudit && contentAudit.length > 0) {
            const mergedContentList = contentAudit.flatMap((audit) => {
                const contentAuditData = audit.data;

                const originalContent = contentAuditData
                    .filter((item) => item.title === "originalContent")
                    .map((item) => item?.data || null);

                const modifiedContent = contentAuditData
                    .filter((item) => item.title === "modifiedContent")
                    .map((item) => item?.data || null);

                return originalContent.map((content, index) => ({
                    ...content,
                    modifiedContent: modifiedContent[index] || null,
                }));
            });

            setTemplateList(mergedContentList);
        } else {
            setShowSenderIntegration(true);
        }
    };
    const handleIptCount = (ipt) => {
        setOverview([
            { title: "Inbox Count", count: parseInt(ipt?.totalInboxCount, 10) || 0 },
            { title: "Spam Count", count: parseInt(ipt?.totalSpamCount, 10) || 0 },
        ]);
        setIptCounts(ipt);
    };
    const fetchAuditAutomationData = () => {
        setLoader(true);
        console.log(reportData, "reportData");
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/fetchSpamWatchAuditReport?sender=${reportData?.email || ""}&workspaceId=${workCtx.activeWorkspace}`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    handleComponentData(data);
                    setActualAuditData(data);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                    setTimeout(() => handleBack(), 2000);
                }
            })
            .catch((err) => {
                console.log(err);
                setErrorSnackbarOpen(true);
                setErrorMessage(err);
            });
    };
    useEffect(() => {
        fetchAuditAutomationData();
    }, []);

    const dummyData = [
        {
            icon: <RocketLaunchIcon />,
            value: "--",
            description: "Per day volume",
        },
        {
            icon: <ChatBubbleIcon />,
            value: "--",
            description: "Per day warmup volume",
        },
    ];

    const handleImapClose = () => {
        navigate(`dashboard/spamwatch?audit=${auditSender}`);
    };
    useEffect(() => {
        if (
            Object.keys(dnsData).length > 0 &&
            (templateList.length > 0 || showSenderIntegration) &&
            ipListedZones.length > 0 &&
            domainListedZones.length > 0
        ) {
            setLoader(false); // Set loading to false when all data is ready
        }
    }, [dnsData, templateList, ipListedZones, domainListedZones]);
    return (
        <Box>
            <>
                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <CustomBreadCrumbs actions={actions} />
                    </Grid>

                    <Grid item>
                        <Grid container spacing={1} alignItems="center">
                            {handleRegenerate && !loader && (
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleRegenerate}>
                                        Regenerate
                                    </Button>
                                </Grid>
                            )}
                            {!loader && (
                                <>
                                    <Grid item>
                                        <DownloadReport data={actualAuditData} title="Spam Watch" />
                                    </Grid>
                                    <Grid item>
                                        <AuditShare
                                            type="spamwatch"
                                            title="Spam Watch"
                                            dataset1={auditSender}
                                            dataset2={workCtx.activeWorkspace}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {loader || Object.keys(dnsData).length <= 0 ? (
                    <SkeletonLoader />
                ) : (
                    <Box className="container_box">
                        <Grid container spacing={2} columns={16}>
                            <Grid item xs={3} sm={8} md={4}>
                                <Card
                                    style={{
                                        width: "100%",
                                        mb: 2,
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                        color: "white",
                                        backgroundColor: "#0884e2",
                                    }}
                                >
                                    <CardContent style={{ textAlign: "center" }}>
                                        <Typography variant="h4">High Score</Typography>
                                        <Typography variant="h2">{score}</Typography>
                                        <Typography variant="subtitle1">out of 100</Typography>
                                    </CardContent>
                                    <Typography
                                        style={{
                                            margin: "10px",
                                            padding: "10px",
                                            backgroundColor: "#9EC4DF",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        The score is calculated directly from these metrics: Inbox rate, Domain health,
                                        and IP insights
                                    </Typography>
                                </Card>
                            </Grid>
                            <ESP iptCounts={iptCounts} />
                            <Grid item xs={3} sm={8} md={4}>
                                {dummyData.map((data, index) => (
                                    <Card
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "120px",
                                            padding: "20px",
                                            marginBottom: "20px",
                                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                            "&:hover": { backgroundColor: "#F7F7F9" },
                                        }}
                                    >
                                        <Avatar style={{ bgcolor: "#E0E0E0", mr: 2 }}>{data.icon}</Avatar>
                                        <CardContent style={{ flexGrow: 1, textAlign: "center" }}>
                                            <Typography variant="h5" component="div">
                                                {data.value}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {data.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Grid>
                        <Box style={{ marginTop: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <ActionComponent onSelect={handlePanelSelect} />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    {selectedPanel === "Blacklist" && (
                                        <BlacklistPanel
                                            domainListedZones={domainListedZones}
                                            ipListedZones={ipListedZones}
                                        />
                                    )}
                                    {selectedPanel === "DNS Reputation" && <DNSRecords dnsRecords={dnsData} />}
                                    {selectedPanel === "Overview" && <DNSOverviewChart inboxSpamCount={overview} />}
                                    {selectedPanel === "TemplateList" && (
                                        <Box>
                                            {showSenderIntegration ? (
                                                <AddIntegration
                                                    handleImapClose={handleImapClose}
                                                    selectedButton={selectedButton}
                                                    senderEmail={reportData ? reportData.email : auditSender}
                                                />
                                            ) : (
                                                // <TemplateList templates={templateList} />
                                                <EmailEditor
                                                    templates={templateList}
                                                    actualTemplate={actualAuditData.contentAudit}
                                                />
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};
export default Report;
