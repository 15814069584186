import React, { useState } from "react";
import { Button, Box, Typography, IconButton, Popover } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
const Download = ({ data, title = "" }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "download-popover" : undefined;

    const handleDownloadPDF = async () => {
        setDisabled(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/generate-pdf`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ title, ...data }), // Replace with actual response data
            });
            if (!response.ok) throw new Error("Failed to download PDF");
            console.log("Response received successfully!");
            const blob = await response.blob();
            console.log("Blob size:", blob.size, "Blob type:", blob.type);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `xemailaudit_${title.trim().toLowerCase().replace(/\s+/g, "")}_report.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            setDisabled(false);
        } catch (error) {
            setDisabled(false);
            console.error("Error downloading PDF:", error);
        }
    };

    return (
        <>
            <IconButton color="primary" onClick={handleOpen} aria-describedby={id} aria-label="Download audit report">
                <DownloadIcon />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box sx={{ p: 2, width: "250px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <InfoIcon color="action" sx={{ mr: 1 }} />
                        <Typography variant="body2" color="textSecondary">
                            Click below to download the audit report.
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<DownloadIcon />}
                        onClick={handleDownloadPDF}
                        fullWidth
                        disabled={disabled}
                    >
                        Download PDF
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default Download;
