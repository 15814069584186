import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import SenderEmails from "./SenderEmail";
import EmailLogs from "./AllEmailLogs";
import FetchReport from "../AuditReportOverview/FetchSpamReport";
import ESPDashboard from "./ESPDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import CreateReport from "../AuditReportOverview/CreateSpamReport";
import TableSkeleton from "../EmailAuditV1/TableSkeleton";
const SpamWatch = () => {
    const location = useLocation();
    const workctx = useContext(WorkspaceContext);
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const audit = params.get("audit");
    console.log(audit, "getParams");
    const rowParam = params.get("row");
    const [row, recipientParam] = rowParam ? rowParam.split(",") : [null, null];
    const recipient = recipientParam ? recipientParam.split("=")[1] : null;
    console.log(recipient, row, audit, "recipientss");
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState({});
    const [tabs, setTabs] = useState([
        { title: "Email Logs", value: "emailLogs", isActive: true },
        { title: "Sender Emails", value: "senderEmails", isActive: false },
    ]);
    const [hideEspDetails, setHideEspDetails] = useState(false);
    const [regenerate, setRegenerate] = useState(false);
    const [emailLogType, setEmailLogType] = useState("all");
    const [selectedEmailRow, setSelectedEmailRow] = useState({});
    const [selectedTab, setSelectedTab] = useState("emailLogs");
    const [dataFetched, setDataFetched] = useState(false); // Track if the data is fetched
    const [key, setKey] = useState(0);
    const handleTabChange = (event, newTab) => {
        if (newTab === "emailLogs") {
            setEmailLogType(Object.keys(selectedEmailRow).length ? "row" : "all");
        }

        if (newTab) {
            setSelectedTab(newTab);
            const updatedTabs = tabs.map((tab) => ({
                ...tab,
                isActive: tab.value === newTab,
            }));
            setTabs(updatedTabs);
        }
    };
    const handleBack = () => {
        navigate("/dashboard/spamwatch", { replace: true });
        setHideEspDetails(false);
        setRegenerate(false);
        setSelectedTab("senderEmails");
        setEmailLogType("all");
        setSelectedEmailRow([]);
        setTabs([
            { title: "Email Logs", value: "emailLogs", isActive: false },
            { title: "Sender Emails", value: "senderEmails", isActive: true },
        ]);
    };
    const handleRowEmailLog = (row) => {
        setEmailLogType("row");
        setSelectedEmailRow(row);
        setSelectedTab("emailLogs");
        setTabs([
            { title: "Activities", value: "emailLogs", isActive: true },
            { title: "Audit Report", value: "auditReport", isActive: false },
        ]);
    };
    const handleViewReport = (row) => {
        setEmailLogType("row");
        setSelectedEmailRow(row);
        setSelectedTab("auditReport");
        setTabs([
            { title: "Audit Report", value: "auditReport", isActive: true },
            { title: "Activities", value: "emailLogs", isActive: false },
        ]);
    };
    const fetchEmailLogByEmail = async (email) => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSendersData?workspaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    const filteredData = data.data.senderDataRecords.filter((record) => record.sender === email);

                    if (filteredData.length > 0) {
                        const rows = filteredData.map((item) => ({
                            email: item.sender,
                            inbox: item.inboxCount,
                            spam: item.spamCount,
                            created: new Date(item.date).toLocaleDateString("en-GB").replace(/\//g, "-"),
                        }));

                        setSelectedEmailRow(rows[0]);
                        setSelectedTab("auditReport");
                        setTabs([
                            { title: "Activities", value: "emailLogs", isActive: false },
                            { title: "Audit Report", value: "auditReport", isActive: true },
                        ]);
                        setEmailLogType("row");
                    }
                }
            })
            .catch((err) => err)
            .finally(() => {
                setLoading(false); // Stop loader
                setDataFetched(true);
            });
    };
    const handleEmailTemplate = () => {
        if (row && row !== "undefined") {
            fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSendersData?workspaceId=${workctx.activeWorkspace}`, {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        const filteredData = data.data.senderDataRecords.filter((record) => record.sender === row);
                        if (filteredData.length > 0) {
                            const rows = filteredData.map((item) => ({
                                email: item.sender,
                                inbox: item.inboxCount,
                                spam: item.spamCount,
                                created: new Date(item.date).toLocaleDateString("en-GB").replace(/\//g, "-"),
                            }));
                            setEmailLogType("row");

                            setSelectedTab("emailLogs");
                            setTabs([
                                { title: "Activities", value: "emailLogs", isActive: true },
                                { title: "Audit Report", value: "auditReport", isActive: false },
                            ]);
                            setSelectedEmailRow(rows[0]);
                        }
                    }
                })
                .catch((err) => err)
                .finally(() => {
                    setLoading(false); // Stop loader
                    setDataFetched(true);
                });
        } else {
            setEmailLogType("all");
            setSelectedTab("emailLogs");
            setTabs([
                { title: "Sender Emails", value: "senderEmails", isActive: false },
                { title: "Email Logs", value: "emailLogs", isActive: true },
            ]);

            setLoading(false); // Stop loader
            setDataFetched(true);
        }
    };

    useEffect(() => {
        if (audit) {
            fetchEmailLogByEmail(audit); //open audit page direct
        } else if (recipient) {
            handleEmailTemplate(recipient); // open email template direct
        } else {
            setLoading(false); // Stop loader if no redirect parameter
            setDataFetched(true); // Mark data as fetched
        }
    }, [audit, recipient]);
    const fetchESPDashboardDetails = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSendersAggregatedData?workspaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setTotalCount(data.totals);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (emailLogType === "all") {
            fetchESPDashboardDetails();
        }
    }, [emailLogType]);
    if (!dataFetched) {
        return <TableSkeleton />;
    }
    const handleRegenerate = () => {
        console.log("regenrateclicked");
        setRegenerate(true);
        setKey((prev) => prev + 1);
    };
    const handleHideEsp = () => {
        console.log("Hide Esp Calling");
        setHideEspDetails((prev) => !prev);
    };
    console.log(hideEspDetails, "hideEspState");
    return (
        <Box p={3} key={key}>
            <Typography variant="h5" gutterBottom>
                Spam Watch
            </Typography>
            <Typography variant="body2" gutterBottom>
                Monitor sender emails, audit reports, and email activity to keep track of spam and inbox counts.
            </Typography>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {emailLogType === "all" && !hideEspDetails && <ESPDashboard totalCount={totalCount} />}
                    <ToggleButtonGroup
                        value={selectedTab}
                        exclusive
                        onChange={handleTabChange}
                        sx={{ mb: 3 }}
                        aria-label="Toggle Navigation"
                    >
                        {tabs.map((tab, index) => (
                            <ToggleButton
                                key={index}
                                value={tab.value}
                                aria-label={tab.title}
                                sx={{
                                    textTransform: "none",
                                    backgroundColor: tab.isActive ? "#0288D1 !important" : "white",
                                    color: selectedTab === tab.isActive ? "white !important" : "black",
                                }}
                            >
                                {tab.title}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>

                    {selectedTab === "senderEmails" && (
                        <SenderEmails handleRowEmailLog={handleRowEmailLog} handleViewReport={handleViewReport} />
                    )}

                    {selectedTab === "emailLogs" && (
                        <EmailLogs
                            handleBack={handleBack}
                            selectedEmailRow={selectedEmailRow}
                            type={emailLogType}
                            recipient={recipient}
                            handleHideEsp={handleHideEsp}
                        />
                    )}

                    {selectedTab === "auditReport" &&
                        (audit || regenerate ? (
                            <CreateReport
                                regenerate={regenerate}
                                clientData={selectedEmailRow}
                                selectedButton={`spamwatch?audit=${selectedEmailRow.email}`}
                                handleBackReport={handleBack}
                                actions={[
                                    { title: "Sender Emails", onClick: handleBack },
                                    { title: "Audit Report", onClick: null },
                                ]}
                                handleRegenerate={handleRegenerate}
                            />
                        ) : (
                            <FetchReport
                                api=""
                                reportData={selectedEmailRow}
                                selectedButton={`spamwatch?audit=${selectedEmailRow.email}`}
                                handleBack={handleBack}
                                actions={[
                                    { title: "Sender Emails", onClick: handleBack },
                                    { title: "Audit Report", onClick: null },
                                ]}
                                handleRegenerate={handleRegenerate}
                            />
                        ))}
                </>
            )}
        </Box>
    );
};

export default SpamWatch;
