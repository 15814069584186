import React, { useContext, useState } from "react";
import "./Register.css";
import cookie from "cookiejs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    CssBaseline,
    Box,
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    TextField,
    CircularProgress,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SnackBar from "./common/SnackBar";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import xaudit1 from "../img/icons/xaudit1.png";
import Avatar from "@mui/material/Avatar";
import { Check, Close } from "@mui/icons-material";
import { AuthContext } from "./Provider/AuthProvider";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const Register = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const ctx = useContext(AuthContext);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState(state !== null ? state.email : "");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [termsCondition, setTermsCondition] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasSpecialCase, setHasSpecialCase] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(!state !== null);
    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (isLogin) {
            navigate("/dashboard/home");
        }
    }, [navigate]);
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(value));
    };
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        setIsLengthValid(value.length >= 8);

        setHasUppercase(/[A-Z]/.test(value));

        setHasLowercase(/[a-z]/.test(value));

        setHasSpecialCase(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    };
    const isAllPasswordConditionsMet = () => {
        return isLengthValid && hasUppercase && hasLowercase && hasSpecialCase;
    };

    const passwordRequirements = [
        {
            text: "Password should be atleast 8 characters",
            check: (password) => password.length >= 8,
        },
        {
            text: "Must have one special character",
            check: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
        },
        {
            text: "Must have one uppercase letter",
            check: (password) => /[A-Z]/.test(password),
        },
        {
            text: "Must have one lowercase letter",
            check: (password) => /[a-z]/.test(password),
        },
    ];
    const isAllConditionMet =
        buttonLoading ||
        !termsCondition ||
        !isAllPasswordConditionsMet() ||
        !firstname ||
        firstname.length < 3 ||
        !isValidEmail ||
        confirmPassword !== password;

    const handleTeamRegister = () => {
        fetch(`${REACT_APP_SERVER_URL}/teamRegister/`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                fname: firstname,
                lname: lastname,
                password: password,
                email: email,
                isTermsAndConditionsVerified: termsCondition ? 1 : 0,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setButtonLoading(false);
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(data.message || data.msg);
                    ctx.handleuser(data.data.user);
                    navigate(data.data.path);
                } else {
                    setButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.data.message || data.msg);
                }
            });
    };
    const registerButtonHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (state?.email) {
            handleTeamRegister();
            return;
        }
        fetch(`${REACT_APP_SERVER_URL}/registerv1`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                fname: firstname,
                lname: lastname,
                password: password,
                email: email,
                isTermsAndConditionsVerified: termsCondition ? 1 : 0,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setButtonLoading(false);
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(response.message);

                    navigate(`/account-verification?email=${email}`, { state: { accountVerifiedPass: password } });

                    return response.json();
                } else {
                    setButtonLoading(false);
                    setErrorSnackbarOpen(true);
                    return response.json();
                }
            })
            .then((data) => {
                setButtonLoading(false);
                setErrorMessage(data.message || data.msg);
                setSuccessMessage(data.message || data.msg);
            });
    };

    return (
        <>
            <ThemeProvider theme={mdTheme}>
                <CssBaseline />
                <Box className="full">
                    <Box
                        sx={{
                            width: "60%",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            justifyItems: "center",
                            backgroundColor: "whitesmoke",
                        }}
                    >
                        <Box sx={{ display: "flex", textAlign: "center", alignItems: "center", marginTop: "10px" }}>
                            <Link exact to="/">
                                <img
                                    src={xaudit1}
                                    alt="/"
                                    style={{ width: "200px", height: "56px", marginTop: "-10px" }}
                                />
                            </Link>
                        </Box>
                        <Box sx={{ margin: "200px", marginTop: "25%" }}>
                            <Typography
                                className="second-child-typography"
                                sx={{ textAlign: "center", fontSize: "30px", fontWeight: "bold", lineHeight: "38px" }}
                            >
                                Don't let your mails got lost!
                            </Typography>

                            <Typography
                                className="second-child-typography"
                                sx={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    fontWeight: "600px",
                                    lineHeight: "28px",
                                    marginRight: "25px",
                                }}
                            >
                                Transform your email strategy and ensure your campaigns reach their target audience.
                                Join us now and elevate your email marketing game!
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ width: "40%", backgroundColor: "white" }}>
                        <Box sx={{ padding: "40px", marginTop: "15px" }}>
                            <Typography
                                sx={{
                                    fontSize: "24px",
                                    fontWeight: 600,
                                    lineHeight: "38px",
                                    pb: 3,
                                    textAlign: "center",
                                }}
                            >
                                Get Started
                            </Typography>
                            <Grid container spacing={2} sx={{ pb: 2 }}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextField
                                        placeholder="First Name"
                                        onChange={(e) => setFirstname(e.target.value)}
                                        fullWidth
                                        error={firstname && firstname.trim().length < 3}
                                        helperText={
                                            firstname && firstname.trim().length < 3
                                                ? "First name must be at least 3 characters long"
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextField
                                        placeholder="Last Name"
                                        onChange={(e) => setLastname(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <TextField
                                placeholder="Email"
                                type="email"
                                onChange={handleEmailChange}
                                value={email}
                                disabled={state !== null}
                                fullWidth
                                error={!isValidEmail && email}
                                helperText={!isValidEmail && email ? "Enter valid email address" : ""}
                                sx={{
                                    pb: 1,
                                    "& .Mui-disabled": {
                                        backgroundColor: "#f0f0f0", // Custom gray color
                                        color: "#888888", // Gray text color
                                    },
                                }}
                            />

                            <TextField
                                sx={{ pb: 2 }}
                                placeholder="Password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                onChange={handlePasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                            {password && !isAllPasswordConditionsMet() && (
                                <ul style={{ listStyleType: "none", paddingLeft: 0, fontSize: "14px" }}>
                                    {passwordRequirements.map((requirement, index) => {
                                        const isValid = requirement.check(password);
                                        return (
                                            <li
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: isValid ? "green" : "#d32f2f",
                                                }}
                                            >
                                                {isValid ? (
                                                    <Check style={{ color: "green", marginRight: 8 }} />
                                                ) : (
                                                    <Close style={{ color: "#d32f2f", marginRight: 8 }} />
                                                )}
                                                {requirement.text}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                            <TextField
                                placeholder="Confirm Password"
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                error={password !== confirmPassword && confirmPassword !== ""} // Show error if passwords do not match
                                helperText={
                                    password !== confirmPassword && confirmPassword !== ""
                                        ? "Password does not match"
                                        : ""
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle confirm password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />

                            <FormControlLabel
                                sx={{
                                    marginTop: "10px",
                                }}
                                label={
                                    <Typography>
                                        <Typography sx={{ mr: 0.5, float: "left" }}>I agree to the</Typography>
                                        <Link
                                            exact
                                            to="#"
                                            onClick={() =>
                                                window.open("https://xemailverify.com/privacy.php", "_blank")
                                            }
                                            style={{ color: "#000" }}
                                        >
                                            Terms of Service & Privacy Policy
                                        </Link>
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        onChange={(e) => setTermsCondition(e.target.checked)}
                                        checked={termsCondition}
                                    />
                                }
                            />
                            <br />
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={registerButtonHandler}
                                fullWidth
                                sx={{
                                    mt: 1,
                                    borderRadius: "6px",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    backgroundColor: "#481BEB",
                                }}
                                disabled={isAllConditionMet}
                            >
                                {buttonLoading && <CircularProgress size={25} />}
                                {!buttonLoading && "Create Account"}
                            </Button>
                            <Typography sx={{ pt: 2 }}>
                                <Typography sx={{ mr: 0.5, float: "left" }}>Already have an account?</Typography>
                                <Link
                                    exact
                                    to="/login"
                                    style={{ color: "rgba(0, 74, 173, 1)", textDecoration: "none" }}
                                >
                                    Login
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </>
    );
};

export default Register;
