import React, { useContext, useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Paper, Chip, Skeleton } from "@mui/material";
import CustomBccImage from "../../img/images/inbox.png";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "../common/SnackBar";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const CustomBccConnect = ({ handleRedirect }) => {
    const workctx = useContext(WorkspaceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [campaignName, setCampaignName] = useState("");
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [bccData, setBccData] = useState([]);
    const [email, setEmail] = useState("");
    const [emails, setEmails] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchBccForIntegrationApp?workspaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    console.log(data, "bccData");
                    setBccData(data.data);
                } else {
                    setErrorMessage(data.message);
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setSkeletonLoader(false));
    }, []);
    const handleAddEmail = () => {
        const trimmedEmails = email.split(",").map((e) => e.trim());
        const validEmails = trimmedEmails.filter((e) => e && isValidEmail(e) && !emails.includes(e));

        if (validEmails.length > 0) {
            setEmails((prev) => [...prev, ...validEmails]);
            setEmail("");
        } else {
            setErrorSnackbarOpen(true);
            setErrorMessage("Invalid or duplicate email(s)");
        }
    };

    const handleRemoveEmail = (emailToRemove) => {
        setEmails((prev) => prev.filter((e) => e !== emailToRemove));
    };

    const handleConnect = () => {
        if (campaignName && emails.length > 0) {
            setIsLoading(true);
            fetch(`${REACT_APP_SERVER_URL}/createAppIntegraion`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    workspaceId: workctx.activeWorkspace,
                    api_key: null,
                    name: "custom",
                    mail: emails,
                    extraToolname: campaignName,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        handleRedirect();
                    } else {
                        setErrorSnackbarOpen(true);
                        setErrorMessage(data.message || data.msg);
                    }
                    setIsLoading(false);
                });
        } else {
            setErrorSnackbarOpen(true);
            setErrorMessage("Please provide a campaign name and at least one email");
        }
    };

    return (
        <Box
            sx={{
                width: "512px",
            }}
        >
            <Paper
                sx={{
                    display: "flex",
                    flexDirection: "column",

                    padding: 2,
                }}
            >
                {skeletonLoader ? (
                    <>
                        <Skeleton variant="text" width={200} height={40} sx={{ mb: 2 }} />
                        <Skeleton variant="text" width={400} height={20} sx={{ mb: 2 }} />
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 2 }} />
                    </>
                ) : (
                    <>
                        <Typography
                            variant="h5"
                            component="div"
                            color="primary"
                            sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
                        >
                            <img src={CustomBccImage} alt="Custom Logo" style={{ height: 40 }} />
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Enter your Bcc senders here to audit campaigns
                        </Typography>
                        <TextField
                            label="Enter Campaign Tool Name"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                        />
                        <TextField
                            label="Add Sender Email"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyPress={(e) => e.key === "Enter" && handleAddEmail()}
                        />
                        <Button variant="outlined" onClick={handleAddEmail} sx={{ mb: 2, width: "50%" }}>
                            Add Email
                        </Button>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                            {emails.map((email, index) => (
                                <Chip
                                    key={index}
                                    label={email}
                                    onDelete={() => handleRemoveEmail(email)}
                                    color="primary"
                                />
                            ))}
                        </Box>
                        {bccData?.length > 0 && (
                            <Typography variant="body2">
                                Note: Add this{" "}
                                {bccData
                                    .map(({ email }, index) => (
                                        <Typography key={index} component="span" fontWeight={"bold"}>
                                            {email}
                                        </Typography>
                                    ))
                                    .reduce((prev, curr) => [prev, ", ", curr])}{" "}
                                in your campaign
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ display: "block" }}
                            disabled={isLoading}
                            onClick={handleConnect}
                        >
                            {isLoading ? <CircularProgress color="inherit" /> : "Connect"}
                        </Button>
                    </>
                )}
            </Paper>

            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};

export default CustomBccConnect;
