import React, { useState, useContext } from "react";
import {
    Button,
    Typography,
    Box,
    CircularProgress,
    Dialog,
    List,
    ListItem,
    IconButton,
    DialogActions,
    ListItemText,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SnackBar from "../common/SnackBar";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Step2 = ({ onPrevious, onClose, domain, txt }) => {
    const workCtx = useContext(WorkspaceContext);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const handlePrevious = () => {
        onPrevious();
    };

    function copy(code) {
        navigator.clipboard.writeText(code);
    }

    const handleVerify = () => {
        setButtonLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/checkVerificationTxt`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                domain: domain,
                workSpaceId: workCtx.activeWorkspace,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(data.message);
                    setTimeout(() => onClose(), 1000);
                } else {
                    setTimeout(() => onClose(), 1000);
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

    return (
        <div className="step-container">
            <Dialog
                open
                onClose={onClose}
                sx={{
                    "& .MuiDialog-paper": {
                        minWidth: "400px",
                        maxWidth: "600px",
                        width: "90%",
                        borderRadius: "20px",
                    },
                }}
            >
                {/* Header */}
                <Box
                    sx={{
                        borderBottom: "1px solid #f1f1f1",
                        padding: "16px",
                        textAlign: "center",
                        backgroundColor: "#f9f9f9",
                    }}
                >
                    <Typography sx={{ fontSize: "20px", fontWeight: 600, lineHeight: "28px" }}>
                        Domain Verification
                    </Typography>
                </Box>
                <Box sx={{ padding: "16px" }}>
                    <Typography sx={{ fontSize: "16px", mb: 3 }}>Verify domain via txt record.</Typography>
                    <Typography sx={{ fontSize: "16px" }}>Verify your ownership of xaudit.com.</Typography>

                    <List sx={{ listStyleType: "decimal", pl: 2 }}>
                        <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                            <ListItemText primary="Add the TXT record to the DNS configuration for your domain." />
                        </ListItem>
                        <ListItem sx={{ display: "list-item", paddingLeft: 0, position: "relative" }}>
                            <ListItemText primary="TXT record:" />
                            <Box
                                sx={{
                                    mt: 1,
                                    border: "1px solid black",
                                    borderRadius: "4px",
                                    padding: "12px",
                                    overflow: "hidden",
                                    backgroundColor: "#f9f9f9",
                                    position: "relative",
                                    display: "block",
                                    textAlign: "left",
                                }}
                            >
                                <Typography variant="body2" sx={{ display: "inline-block", wordWrap: "break-word" }}>
                                    {txt}
                                </Typography>
                                <IconButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSuccessSnackbarOpen(true);
                                        setSuccessMessage("Code Copied");
                                        copy(txt);
                                    }}
                                    sx={{
                                        position: "absolute",
                                        top: "4px",
                                        right: "10px",
                                        color: "#004aad",
                                    }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </Box>
                        </ListItem>
                        <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                            <ListItemText primary="Click Verify." />
                        </ListItem>
                    </List>

                    <Typography sx={{ fontSize: "14px", mb: 1 }}>
                        When Google finds the DNS record that you have added, we will verify your ownership of the
                        domain. To stay verified, do not remove the DNS record, even after the verification succeeds.
                        (DNS changes may take some time, and if we do not find the record immediately, we will check
                        periodically.)
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                        Verification may take up tp 6 to 7 hours. You can check back later.
                    </Typography>
                </Box>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handlePrevious} sx={{ minWidth: "100px" }}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleVerify}
                        disabled={buttonLoading}
                        sx={{
                            minWidth: "100px",
                        }}
                    >
                        {buttonLoading ? <CircularProgress size={24} /> : "Verify"}
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </div>
    );
};

export default Step2;
