import { useState, useEffect, useMemo } from "react";
import TableComponent from "../../common/TableComponent";
import { Box, Button, TextField, InputAdornment, IconButton, Select, MenuItem } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
const MarketingCampaign = ({ handleAuditForm, rows, handleShowConnected }) => {
    const [searchText, setSearchText] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [noDataCount, setNoDataCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [statusFilter, setStatusFilter] = useState("");
    const columns = [
        { field: "campaignName", headerName: "Campaign Name", sortable: true },
        { field: "status", headerName: "Status", sortable: true },
        { field: "created", headerName: "Created", sortable: true },
    ];

    const filteredSender = useMemo(() => {
        const updatedRows = rows.map((item) => ({
            id: item.id,
            campaignName: item.settings.title === "" ? "Empty" : item.settings.title,
            status: item.status === "save" ? "Draft" : item.status === "sending" ? "In Progress" : "Sent",
            created: new Date(item.create_time).toLocaleDateString("en-GB").replace(/\//g, "-"),
        }));
        console.log(updatedRows, "updatedRowsssssssssss");

        return updatedRows.filter((campaignList) => {
            const matchesSearch = searchText
                ? campaignList.campaignName.toLowerCase().includes(searchText.toLowerCase())
                : true;
            const matchesDate = selectedDate ? dayjs(selectedDate).format("DD-MM-YYYY") === campaignList.created : true;
            const matchesStatus = statusFilter ? campaignList.status === statusFilter : true;
            return matchesSearch && matchesDate && matchesStatus;
        });
    }, [rows, searchText, selectedDate, statusFilter]);
    useEffect(() => {
        console.log(filteredSender, "i am filtereds sender");
        if (filteredSender.length > 0) {
            setLoader(false);
        } else {
            setNoDataCount((prev) => {
                const newCount = prev + 1;
                if (newCount >= 3) {
                    setLoader(false);
                }
                return newCount;
            });
        }
    }, [filteredSender]);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
                <SettingsIcon onClick={handleShowConnected} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                    mb: 3,
                    flexWrap: "wrap",
                }}
            >
                <TextField
                    placeholder="Search by Campaign Name"
                    size="small"
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: "300px" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{ display: "flex", gap: 2 }}>
                    <Select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        displayEmpty
                        size="small"
                        sx={{ minWidth: 150 }}
                    >
                        <MenuItem value="">All Status</MenuItem>
                        <MenuItem value="Draft">Draft</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                        <MenuItem value="Sent">Sent</MenuItem>
                    </Select>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {showDatePicker ? (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={selectedDate}
                                    onChange={(newDate) => setSelectedDate(newDate)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            placeholder="Select Date"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton>
                                                        <DateRangeIcon />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        ) : (
                            <IconButton onClick={() => setShowDatePicker(true)}>
                                <DateRangeIcon />
                            </IconButton>
                        )}
                        {(selectedDate || showDatePicker) && (
                            <IconButton
                                onClick={() => {
                                    setSelectedDate(null);
                                    setShowDatePicker(false);
                                }}
                                color="primary"
                                title="Clear Date"
                            >
                                <ClearIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </Box>
            <TableComponent
                rows={filteredSender}
                columns={columns}
                isLoading={loader}
                onClick={(row) => handleAuditForm(row)}
            />
        </Box>
    );
};
export default MarketingCampaign;
