//import { useEffect, useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useState } from "react";

const MarketingPlatform = () => {
    const [disabled, setDisabled] = useState(false);
    const handleMailChimp = () => {
        setDisabled(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/redirectToMailchimpLogin`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setDisabled(false);
                if (data.success) {
                    window.location.href = data.redirect_url;
                    //setIframeSrc(data.redirect_url);
                }
            });
    };
    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: "auto",
                padding: "20px",
                textAlign: "center",
            }}
        >
            <IconButton disableRipple sx={{ fontSize: 80, color: "black" }}>
                <MailOutlineIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                Connect Mailchimp Marketing Platform
            </Typography>
            <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                Easily audit campaigns from mailchimp marketing platforms by connecting your tools.
            </Typography>

            <Button
                variant="contained"
                color="info"
                disabled={disabled}
                sx={{ mt: 3, textTransform: "none", px: 4 }}
                onClick={handleMailChimp}
            >
                Connect Mailchimp
            </Button>
        </Box>
        // <Box
        //     sx={{
        //         width: "100%",
        //         height: "100vh",
        //         overflow: "hidden",
        //     }}
        // >
        //     {iframeSrc ? (
        //         <iframe
        //             src={iframeSrc}
        //             title="Mail Chimp"
        //             width="100%"
        //             height="100%" // Ensures the iframe occupies the full container
        //             style={{
        //                 border: "none", // Removes the border around the iframe
        //                 overflow: "hidden", // Ensures no internal scrolling
        //             }}
        //             scrolling="no" // Prevents scrollbars in some browsers
        //         ></iframe>
        //     ) : (
        //         <Box
        //             sx={{
        //                 maxWidth: 800,
        //                 margin: "auto",
        //                 padding: "20px",
        //                 textAlign: "center",
        //             }}
        //         >
        //             <IconButton disableRipple sx={{ fontSize: 80, color: "black" }}>
        //                 <MailOutlineIcon fontSize="inherit" />
        //             </IconButton>
        //             <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        //                 Connect Mailchimp Marketing Platform
        //             </Typography>
        //             <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
        //                 Easily audit campaigns from mailchimp marketing platforms by connecting your tools.
        //             </Typography>

        //             <Button
        //                 variant="contained"
        //                 color="info"
        //                 sx={{ mt: 3, textTransform: "none", px: 4 }}
        //                 onClick={handleMailChimp}
        //             >
        //                 Connect Mailchimp
        //             </Button>
        //         </Box>
        //     )}
        // </Box>
    );
};

export default MarketingPlatform;
