import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import DashBoard from "./components/Dashboard/DashBoard";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import VerifyAccount from "./components/VerifyAccount";
import DnsRecords from "./components/Dns Records/DnsRecords";
import IpBlacklist from "./components/IpBlacklist/IpBlacklist";
import IpBlacklistDetails from "./components/IpBlacklistDetails/IpBlacklistdetails";
import DomainReputation from "./components/DomainReputation/DomainReputation";
import InboxPlacement from "./components/InboxPlacement/Inboxplacement";
import ReputationFeatures from "./components/ReputationFeatrues.js/ReputationFeatures";
import InboxTest from "./components/Inboxtest/Inboxtest";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import Setting from "./components/Setting";
import VerifyEmail from "./components/ForgotPassword/VerifyEmail";
import AutoInboxTest from "./components/AutoinboxTest/Autoinboxtest";
import DomainCalculator from "./components/Domaincalculator/Domaincalculator";
import ExtraTools from "./components/Extra tools/Tool";
import FindEsp from "./components/FindEsp/FindEsp";
import SpreadSheet from "./components/SpreadSheet/Spreadsheet";
import DataDashboard from "./components/DataDashboard/DataDashboard";
import AuditReport from "./components/AuditReport/AuditReport";
import GoogleRedirect from "./components/EmailOnBoard/GoogleRedirect";
import BccDetails from "./components/BccDetails/BccDetails";
import OutlookRedirect from "./components/EmailOnBoard/OutlookRedirect";
import EmailHeaderAnalyze from "./components/EmailHeaderAnalyze";
import DomainGenerator from "./components/DomainGenerator";
import Integration from "./components/Integration";
import ManualAudit from "./components/manualAudit/ManualAudit";
import ManualAccordian from "./components/manualAudit/ManualAccordian";
import CheckReport from "./components/AuditReport/CheckReport";
import NotFound from "./components/NotFound";
import AccountVerification from "./components/AccountVerification";
import ResetMailSent from "./components/ForgotPassword/ResetMailSent";
import AccountActivated from "./components/GetStarted/EmailVerified";
import AddMember from "./components/GetStarted/WorkSpace/AddMember";
import AddDomain from "./components/GetStarted/WorkSpace/AddDomain";
import VerifyTeam from "./components/GetStarted/WorkSpace/VerifyTeam";
import { WorkspaceContext } from "./components/Provider/WorkspaceProvider";
import ProtectedRoute from "./components/RouteRestricted/ProtectedRoute";
import SpamChecker from "./components/SpamChecker/spamcheck";
import Billing from "./components/Billing";
import PaymentSuccess from "./components/Billing/PayementSuccess";
import PaymentFail from "./components/Billing/PaymentFail";
import SetupFetchInterceptor from "./components/Interceptors/FetchInterceptor";
import { AuthContext } from "./components/Provider/AuthProvider";
import AuthorizedRoute from "./components/RouteRestricted/AuthorizedRoute";
import EmailAuditing from "./components/EmailAudit";
import EmailAuditV1 from "./components/EmailAuditV1";
import Spamwatch from "./components/Spamwatch";
import SocketProvider from "./components/Provider/SocketProvider";
import MainDashboard from "./components/MainDashboard";
import ShareableReport from "./components/AuditReportOverview/ShareableReport";
const OwnerRoute = ({ element }) => {
    const workspaceContext = useContext(WorkspaceContext);
    let isOwner = workspaceContext.workspaceLimit.role === 2 || workspaceContext.workspaceLimit.role_id === 2 || false;
    if (isOwner) {
        return element;
    } else {
        window.location.href = "/login";
        return null;
    }
};
const App = () => {
    const authCtx = useContext(AuthContext);

    SetupFetchInterceptor(authCtx.handleExpiredPlan);
    return (
        <SocketProvider>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/forgotPassword" element={<ForgotPassword />} />
                <Route exact path="/resetPassword" element={<ResetPassword />} />
                <Route exact path="/reset-verification-mail-sent" element={<ResetMailSent />} />
                <Route exact path="/verifyemail" element={<VerifyEmail></VerifyEmail>} />
                <Route exact path="/verify-account/:id" element={<VerifyAccount />} />
                <Route exact path="/account-activated" element={<AccountActivated />} />
                <Route exact path="/account-verification" element={<AccountVerification />} />
                <Route exact path="/team/register" element={<Register />} />
                <Route exact path="/verify-team/:token" element={<VerifyTeam />} />
                <Route exact path="/billing" element={<Billing />} />
                <Route exact path="/reports/:type/:dataset1/:dataset2" element={<ShareableReport />} />

                <Route
                    exact
                    path="/test/:id"
                    element={
                        <ProtectedRoute>
                            <InboxTest />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/check/:code"
                    element={
                        <ProtectedRoute>
                            <IpBlacklistDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/auto-test"
                    element={
                        <ProtectedRoute>
                            <AutoInboxTest />
                        </ProtectedRoute>
                    }
                />

                <Route
                    exact
                    path="/googleredirect"
                    element={
                        <ProtectedRoute>
                            <GoogleRedirect />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/outlookredirect"
                    element={
                        <ProtectedRoute>
                            <OutlookRedirect />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    exact
                    path="/workspace"
                    element={
                        <ProtectedRoute>
                            <OwnerRoute element={<AddMember />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/add-domain"
                    element={
                        <ProtectedRoute>
                            <OwnerRoute element={<AddDomain />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/payment-success"
                    element={
                        <ProtectedRoute>
                            <PaymentSuccess />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/payment-fail"
                    element={
                        <ProtectedRoute>
                            <PaymentFail />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="dashboard"
                    element={
                        <ProtectedRoute>
                            <DashBoard />
                        </ProtectedRoute>
                    }
                >
                    <Route path="" element={<Navigate to="/dashboard/home" />} />
                    <Route
                        path="home"
                        element={
                            <AuthorizedRoute title="Dashboard">
                                <MainDashboard />
                            </AuthorizedRoute>
                        }
                    />
                    <Route
                        path="blacklist-checker"
                        element={
                            <AuthorizedRoute title="Blacklist Checker">
                                <IpBlacklist />
                            </AuthorizedRoute>
                        }
                    />
                    <Route
                        path="domain-generator"
                        element={
                            <AuthorizedRoute title="Domain Name Generator">
                                <DomainGenerator />
                            </AuthorizedRoute>
                        }
                    />

                    <Route
                        path="email-header-analyze"
                        element={
                            <AuthorizedRoute title="Email Header Analyzer">
                                <EmailHeaderAnalyze />
                            </AuthorizedRoute>
                        }
                    />
                    <Route
                        path="setting"
                        element={
                            <AuthorizedRoute title="Settings">
                                <Setting />
                            </AuthorizedRoute>
                        }
                    />
                    <Route
                        path="DomainReputation"
                        element={
                            <AuthorizedRoute title="Domains">
                                <DomainReputation />
                            </AuthorizedRoute>
                        }
                    />
                    <Route
                        path="spamwatch"
                        element={
                            <AuthorizedRoute title="Spam Watch">
                                <Spamwatch />
                            </AuthorizedRoute>
                        }
                    />
                    <Route
                        exact
                        path="DomainReputation/features/:domain/:clientName"
                        element={<ReputationFeatures />}
                    />
                    <Route
                        path="dns-records"
                        element={
                            <AuthorizedRoute title="DNS Records">
                                <DnsRecords />
                            </AuthorizedRoute>
                        }
                    />

                    <Route
                        path="InboxPlacement"
                        element={
                            <AuthorizedRoute>
                                <InboxPlacement />
                            </AuthorizedRoute>
                        }
                    />
                    <Route
                        path="domain-calculator"
                        element={
                            <AuthorizedRoute title="Cold Email Strategy">
                                <DomainCalculator />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="spammy-word-checker"
                        element={
                            <AuthorizedRoute title="Spam Checker">
                                <SpamChecker />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="extra-tools"
                        element={
                            <AuthorizedRoute title="Tools">
                                <ExtraTools />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="find-ESP"
                        element={
                            <AuthorizedRoute title="ESP Finder">
                                <FindEsp />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="spreadsheet"
                        element={
                            <AuthorizedRoute>
                                <SpreadSheet />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="dataDashboard"
                        element={
                            <AuthorizedRoute>
                                <DataDashboard />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="integration"
                        element={
                            <AuthorizedRoute title="Integrations">
                                <Integration />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="audit-report"
                        element={
                            <AuthorizedRoute>
                                <AuditReport />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="bcc-details"
                        element={
                            <AuthorizedRoute>
                                <BccDetails />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="check-report"
                        element={
                            <AuthorizedRoute>
                                <CheckReport />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route
                        path="manualAudit"
                        element={
                            <AuthorizedRoute>
                                <ManualAudit />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route path="manualAudit/:selectedSender" element={<ManualAccordian />}></Route>
                    <Route
                        path="email-audit-v1"
                        element={
                            <AuthorizedRoute title="Email Audit">
                                <EmailAuditV1 />
                            </AuthorizedRoute>
                        }
                    ></Route>
                    <Route path="email-audit-v0" element={<EmailAuditing />} />
                </Route>
                <Route exact path="*" element={<NotFound />} />
            </Routes>
        </SocketProvider>
    );
};

export default App;
