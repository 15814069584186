import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Box, Typography, TextField, IconButton, InputAdornment } from "@mui/material";
import TableComponent from "../common/TableComponent";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
const SenderEmails = ({ handleRowEmailLog, handleViewReport }) => {
    const workctx = useContext(WorkspaceContext);
    const [searchText, setSearchText] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [senderData, setSenderData] = useState([]);
    const [loader, setLoader] = useState(false);
    let count = useRef(0);
    const columns = [
        { field: "email", headerName: "Sender Email", sortable: true },
        { field: "inbox", headerName: "Inbox", sortable: true },
        { field: "spam", headerName: "Spam", sortable: true },
        { field: "created", headerName: "Created", sortable: true },
        { field: "action", headerName: "Actions", sortable: false },
    ];
    const Actions = [
        { title: "View Report", click: (row) => handleViewReport(row) },
        { title: "Email Logs", click: (row) => handleRowEmailLog(row) },
    ];

    const fetchSenderEmails = () => {
        setLoader(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/fetchSendersData?workspaceId=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSenderData(data.data.senderDataRecords); // hotfix
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setTimeout(() => setLoader(false), 2000));
    };
    const filteredSender = useMemo(() => {
        const updatedRows = senderData.map((item) => ({
            email: item.sender,
            inbox: item.inboxCount,
            spam: item.spamCount,
            created: dayjs(item.date).format("DD-MM-YYYY"),
        }));

        return updatedRows.filter((emailList) => {
            const matchesSearch = searchText ? emailList.email.toLowerCase().includes(searchText.toLowerCase()) : true;
            const matchesDate = selectedDate ? dayjs(selectedDate).format("DD-MM-YYYY") === emailList.created : true;

            return matchesSearch && matchesDate;
        });
    }, [senderData, searchText, selectedDate]);
    useEffect(() => {
        console.log(filteredSender, "i am filtereds sender");
        if (filteredSender.length > 0) {
            setLoader(false);
        } else {
            count.current = count.current + 1;
            if (count.current >= 3) {
                setLoader(false);
            }
        }
    }, [filteredSender]);
    useEffect(() => {
        fetchSenderEmails();
    }, []);

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Sender Email Overview
            </Typography>
            <Typography variant="body2" gutterBottom>
                Below is a list of sender emails from your integrated campaigns. Click on any email to view its audit
                reports and email logs.
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                    mb: 3,
                    flexWrap: "wrap",
                }}
            >
                <TextField
                    placeholder="Search by Sender Emails"
                    size="small"
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: "300px" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {showDatePicker ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={selectedDate}
                                onChange={(newDate) => setSelectedDate(newDate)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        placeholder="Select Date"
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton>
                                                    <DateRangeIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    ) : (
                        <IconButton onClick={() => setShowDatePicker(true)}>
                            <DateRangeIcon />
                        </IconButton>
                    )}
                    {(selectedDate || showDatePicker) && (
                        <IconButton
                            onClick={() => {
                                setSelectedDate(null);
                                setShowDatePicker(false);
                            }}
                            color="primary"
                            title="Clear Date"
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <TableComponent
                rows={filteredSender}
                columns={columns}
                actions={Actions}
                onClick={(row) => handleRowEmailLog(row)}
                isLoading={loader}
            />
        </Box>
    );
};
export default SenderEmails;
