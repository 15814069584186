import React, { useState } from "react";
import { Box, Typography, IconButton, Popover, Button } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import SnackBar from "../common/SnackBar";
const AuditShare = ({ title, dataset1 = "", dataset2 = "", type }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCopySuccess(false);
    };

    const handleShare = () => {
        const shareableLink = `${window.location.origin}/reports/${type}/${dataset1}/${dataset2}`; // Example shareable link

        navigator.clipboard.writeText(shareableLink);
        setSnackbarMessage("Link copied to clipboard!");
        setSnackbarOpen(true);
        setCopySuccess(true);
    };

    const open = Boolean(anchorEl);
    const id = open ? "share-popover" : undefined;

    return (
        <Box>
            <IconButton
                color="primary"
                onClick={handleOpen}
                aria-describedby={id}
                aria-label={`Share ${title.trim()} Report`}
            >
                <ShareIcon />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box sx={{ p: 2, width: "250px" }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Share {title} Report
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2, wordWrap: "break-word" }}>
                        {window.location.origin}/reports/{type}/{dataset1}/{dataset2}
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<ShareIcon />}
                        onClick={handleShare}
                        fullWidth
                    >
                        {copySuccess ? "Copied" : "Copy link"}
                    </Button>
                    {copySuccess && (
                        <Typography variant="body2" color="success.main" sx={{ mt: 1, textAlign: "center" }}>
                            {copySuccess}
                        </Typography>
                    )}
                </Box>
            </Popover>
            <SnackBar
                type="success"
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default AuditShare;
