import { useEffect, useState } from "react";
import CardComponent from "../../IntegrationModule/CardComponent";
import {
    Box,
    Container,
    Typography,
    Dialog,
    Button,
    TextField,
    Grid,
    IconButton,
    Alert,
    Snackbar,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Google from "../../../img/icons/google.png";
import Outlook from "../../../img/icons/outlook.png";
import imap_smtp from "../../../img/icons/imap_smtp.png";
import ImapSmtp from "../../../img/icons/imap_smtp.png";
import { Link, useNavigate } from "react-router-dom";
import cookie from "cookiejs";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const AddDomain = () => {
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [smtpPassword, setSmtpPassword] = useState("");

    const [imapPassword, setImapPassword] = useState("");
    const [smtpHost, setSmtpHost] = useState("");
    const [smtpPort, setSmtpPort] = useState("");
    const [imapHost, setImapHost] = useState("");
    const [imapPort, setImapPort] = useState("");
    const [smtpUsername, setSmtpUsername] = useState("");
    const [ImapDialogBox, setIMAPDialogBox] = useState(false);
    const [smtpDialogBox, setSmtpDialogBox] = useState(false);
    const [imapSmtp, setImapSmtp] = useState(false);
    const [selectedButton, setSelectedButton] = useState("signup");
    const navigate = useNavigate();
    useEffect(() => {
        if (!cookie.get("token")) {
            navigate("/login");
        }
    }, []);
    const handleCardClick = (cardType) => {
        switch (cardType) {
            case "google":
                googleUserAdd();
                break;
            case "outlook":
                outlookUserAdd();
                console.log("Calling Outlook function");
                break;
            case "imapSmtp":
                handleOpenSmtp();
                break;

            default:
                console.log("Invalid value pressed");
        }
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    function handleOpenSmtp() {
        setImapSmtp(true);
        setSmtpDialogBox(true);
    }
    function handleclose() {
        setImapSmtp(false);
        setSmtpDialogBox(false);
        setIMAPDialogBox(false);
    }

    const googleUserAdd = async () => {
        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/auth/google?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            window.location.href = data.data;
        } catch (error) {
            console.error("Error during Google authentication:", error);
        }
    };

    async function outlookUserAdd() {
        try {
            try {
                const response = await fetch(`${REACT_APP_SERVER_URL}/auth/outlook?appType=${selectedButton}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                window.location.href = data.data;
            } catch (error) {
                console.error("Error during Google authentication:", error);
            }
        } catch (error) {
            console.log(error, "error is coming...");
        }
    }
    function handleSmtpEvent() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/auth/Smtp?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    mail: smtpUsername,
                    credentials: smtpPassword,
                    smtp_host: smtpHost,
                    smtp_port: smtpPort,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("data getting from here", data);
                    if (data.success) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.message || data.msg);
                        setSmtpDialogBox(false);
                        setIMAPDialogBox(true);
                        return;
                    }
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                });
        } catch (error) {
            console.error(error, "getting the error...");
        }
    }

    function handleImapEvent() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/auth/Imap`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    mail: smtpUsername,
                    imap_port: imapPort,
                    imap_host: imapHost,
                    credentials: smtpPassword,
                    smtp_host: smtpHost,
                    smtp_port: smtpPort,
                    appType: selectedButton,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("data getting from here", data);
                    if (data.success) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.message || data.msg);
                        setImapSmtp(false);
                        setSmtpDialogBox(false);
                        setIMAPDialogBox(false);
                        navigate("/dashboard/home");
                        return;
                    }
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message || data.msg);
                    setImapSmtp(false);
                    setSmtpDialogBox(false);
                    setIMAPDialogBox(false);
                });
        } catch (error) {
            console.error(error, "getting the error...");
        }
    }

    function handleOpenSmtp() {
        setImapSmtp(true);
        setSmtpDialogBox(true);
    }
    return (
        <Container>
            {!imapSmtp ? (
                <Container sx={{ marginTop: "20px" }}>
                    <Link to="/workspace" style={{ marginTop: "1rem", fontSize: "20px", textDecoration: "underline" }}>
                        Back
                    </Link>
                    <Typography sx={{ fontSize: "30px", fontWeight: "700", textAlign: "center" }}>
                        Connect your domain
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                        To audit your emails effectively, please add the domain(s) you want to analyze.
                    </Typography>
                    <Box display="flex" justifyContent="space-evenly" sx={{ marginTop: "20px" }}>
                        <CardComponent
                            title="Google/Gsuite"
                            typography="Add Your Google / Gsuite accounts here."
                            image={Google}
                            type="google"
                            onCardClick={handleCardClick}
                        />
                        <CardComponent
                            title="OUTLOOK / M365"
                            typography="Add Your Outlook / M365 accounts here."
                            image={Outlook}
                            type="outlook"
                            onCardClick={handleCardClick}
                        />
                        <CardComponent
                            title="IMAP/SMTP"
                            typography="Add Your IMAP / SMTP accounts here."
                            image={ImapSmtp}
                            type="imapSmtp"
                            onCardClick={handleCardClick}
                        />
                    </Box>
                    <Link
                        to="/dashboard/home"
                        style={{ marginTop: "1rem", fontSize: "20px", textDecoration: "underline", float: "right" }}
                    >
                        Skip
                    </Link>
                </Container>
            ) : (
                <Box sx={{ my: 3 }}>
                    {imapSmtp && smtpDialogBox && (
                        <Dialog
                            open={smtpDialogBox}
                            onClose={handleclose}
                            PaperProps={{
                                style: {
                                    borderRadius: "20px",
                                    padding: "24px",
                                },
                            }}
                        >
                            <>
                                <Box sx={{ mb: 2 }}>
                                    <Button
                                        sx={{
                                            py: 1,
                                            color: "#481BEB",
                                            borderColor: "#E7E7E7",
                                            fontSize: "12px",
                                            fontFamily: "'Inter', sans-serif",
                                            "&:hover": {
                                                borderColor: "#E7E7E7",
                                            },
                                        }}
                                        variant="outlined"
                                        onClick={handleclose}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                                    <img src={imap_smtp} alt="imap_smtp" style={{ float: "left", width: "25px" }} />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Connect to</Typography>
                                        <Typography>SMTP</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        label="Username"
                                        required
                                        value={smtpUsername}
                                        onChange={(e) => setSmtpUsername(e.target.value)}
                                        sx={{ mb: 2 }}
                                    />
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="SMTP Password"
                                        required
                                        value={smtpPassword}
                                        onChange={(e) => setSmtpPassword(e.target.value)}
                                        sx={{ mb: 2 }}
                                    />
                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="SMTP Host"
                                                fullWidth
                                                value={smtpHost}
                                                onChange={(e) => setSmtpHost(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="SMTP Port"
                                                type="number"
                                                fullWidth
                                                value={smtpPort}
                                                onChange={(e) => setSmtpPort(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            disabled={!smtpUsername || !smtpPassword || !smtpHost || !smtpPort}
                                            onClick={handleSmtpEvent}
                                            variant="contained"
                                            sx={{ textTransform: "none", boxShadow: "none" }}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        </Dialog>
                    )}
                    {imapSmtp && ImapDialogBox && (
                        <Dialog
                            open={ImapDialogBox}
                            PaperProps={{
                                style: {
                                    borderRadius: "20px",
                                    padding: "24px",
                                },
                            }}
                        >
                            <Box sx={{ mb: 2 }}>
                                <IconButton size="small" onClick={handleclose}>
                                    <ArrowBackIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                                    <img src={imap_smtp} alt="imap_smtp" style={{ float: "left", width: "25px" }} />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>Connect to</Typography>
                                        <Typography>IMAP</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        label="IMAP Password"
                                        type="password"
                                        required
                                        value={imapPassword}
                                        onChange={(e) => setImapPassword(e.target.value)}
                                        sx={{ mb: 2 }}
                                    />
                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="IMAP Host"
                                                fullWidth
                                                value={imapHost}
                                                onChange={(e) => setImapHost(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                label="IMAP Port"
                                                type="number"
                                                fullWidth
                                                value={imapPort}
                                                onChange={(e) => setImapPort(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            onClick={handleImapEvent}
                                            variant="contained"
                                            sx={{ textTransform: "none", boxShadow: "none" }}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        </Dialog>
                    )}
                </Box>
            )}
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};
export default AddDomain;
