import { useState, useEffect, useContext } from "react";
import { Box, IconButton } from "@mui/material";
import InitialPlatform from "./InitialPlatform";
import MarketingPlatform from "./MarketingPlatFormReport";
import TestAudit from "./TestAudit";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../common/SnackBar";
import Connected from "./ConnectedMailChimp";
import TableSkeleton from "../TableSkeleton";
const AuditHome = ({ secondParam, mailchimpConnected, showPlatformCampaignList }) => {
    const navigate = useNavigate();
    const [connectedDetails, setConnectedDetails] = useState({});
    const [marketingPlatformTable, setMarketingPlatformTable] = useState(false);
    const [showRunAudit, setShowRunAudit] = useState(secondParam ? true : false);
    const [isMailChimpConnected, setMailChimpConnected] = useState(mailchimpConnected);
    const [loader, setLoader] = useState(!isMailChimpConnected);
    const [rows, setRows] = useState([]);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedRow, setSelectedRow] = useState([]);
    const workctx = useContext(WorkspaceContext);
    const handleAuditForm = (row) => {
        setShowRunAudit(true);
        setSelectedRow(row);
    };
    const handleBack = () => {
        navigate(`/dashboard/email-audit-v1?tab=marketing`, { replace: true });
        setShowRunAudit(false);
        fetchCampaign();
    };
    const fetchCampaign = () => {
        setLoader(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/mailchimp/campaigns?workspace_id=${workctx.activeWorkspace}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success && data?.connected) {
                    setRows(data.data.campaigns);
                    setMarketingPlatformTable(true);
                    setConnectedDetails(data.connected);
                }
                if (!data.success && data?.connected) {
                    setRows([]);
                    setMarketingPlatformTable(true);
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                    setConnectedDetails(data.connected);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoader(false));
    };

    useEffect(() => {
        if (!isMailChimpConnected) {
            fetchCampaign();
        }
    }, []);
    const handleAuditCampaign = () => {
        setMailChimpConnected(false);
    };
    if (loader) {
        return <TableSkeleton />;
    }

    return (
        <Box>
            <Box>
                <Box>
                    {showRunAudit ? (
                        <TestAudit
                            //thirdParam={thirdParam ? thirdParam : null}
                            secondParam={secondParam}
                            handleBack={handleBack}
                            rows={rows}
                            selectedRow={selectedRow}
                            showPlatformCampaignList={showPlatformCampaignList}
                        />
                    ) : (
                        <Box>
                            {isMailChimpConnected ? (
                                <Connected
                                    handleAuditCampaign={handleAuditCampaign}
                                    connectedDetails={connectedDetails}
                                    handleBack={handleBack}
                                />
                            ) : (
                                <Box>
                                    {marketingPlatformTable ? (
                                        <MarketingPlatform
                                            handleAuditForm={handleAuditForm}
                                            rows={rows}
                                            handleBack={handleBack}
                                            handleShowConnected={() => setMailChimpConnected(true)}
                                        />
                                    ) : (
                                        <InitialPlatform handleAuditForm={handleAuditForm} handleBack={handleBack} />
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};
export default AuditHome;
