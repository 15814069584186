import { useRef, useState } from "react";
import he from "he";
import {
    Paper,
    Box,
    IconButton,
    ListItem,
    ListItemText,
    Typography,
    Grid,
    LinearProgress,
    Tooltip,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SnackBar from "../common/SnackBar";

const TemplateList = ({ templates, actualTemplate }) => {
    const extractedData = actualTemplate.map((entry) => {
        const originalContent = entry.data.find((item) => item.title === "originalContent")?.data || null;
        const decodedContent = originalContent ? he.decode(originalContent.body) : "";
        const decodedSubject = originalContent ? he.decode(originalContent.subject) : "";
        const linkCheck = entry.data.find((item) => item.title === "replaceExternalLinks result")?.data?.data;
        const spamWord = entry.data.find((item) => item.title === "modifiedContent")?.data || {};
        const signature = entry.data.find((item) => item.title === "checkForSignatures result")?.data?.data;
        const imageCheck = entry.data.find((item) => item.title === "replaceImages result")?.data?.data;
        const safeParse = (data) => {
            try {
                return data && typeof data === "string" ? JSON.parse(data) : [];
            } catch (error) {
                console.error("Error parsing JSON:", error);
                return []; // Return null if parsing fails
            }
        };

        // Return the extracted fields with parsed data
        return {
            originalContent: { body: decodedContent, subject: decodedSubject },
            linkCheck: safeParse(linkCheck), // Parse linkCheck safely
            spamWord: safeParse(spamWord), // Parse spamWord safely
            signature: safeParse(signature),
            imageCheck: safeParse(imageCheck),
        };
    });
    console.log(extractedData, "datttaaaaaaaaaaaaaaaaaaaaaaaaaa");
    const [selectedTemplate, setSelectedTemplate] = useState(extractedData[0]);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const scrollRef = useRef(null);

    const handleCopy = (content) => {
        setSuccessSnackbarOpen(true);
        setSuccessMessage("Copied to clipboard");
        navigator.clipboard.writeText(content);
    };

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft -= 150;
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += 150;
        }
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };
    const generateSpamAnalysis = (selectedTemplate) => {
        console.log(
            selectedTemplate,
            "selected",
            Object.keys(selectedTemplate.spamWord).length > 0,
            Object.keys(selectedTemplate.spamWord)
        );
        const spamLinks = Array.isArray(selectedTemplate.linkCheck)
            ? selectedTemplate.linkCheck.filter((link) => link.status === "spam" && link.link !== "All links removed")
            : [];
        const spamSignature = Array.isArray(selectedTemplate.signature)
            ? selectedTemplate.signature.filter(
                  (sign) => sign.status === "spam" && sign.signature !== "All signatures removed"
              )
            : [];
        const spamImage = Array.isArray(selectedTemplate.imageCheck)
            ? selectedTemplate.imageCheck.filter((img) => img.status === "spam" && img.image !== "All images removed")
            : [];
        const spamWordsCount =
            typeof selectedTemplate.spamWord === "object" && selectedTemplate.spamWord !== null
                ? Object.keys(selectedTemplate.spamWord).length > 0 && selectedTemplate.spamWord.analysis
                    ? selectedTemplate.spamWord.analysis.spammy_words_counts || 0
                    : 0
                : 0;
        const spamAnalysis = [
            {
                label: "Spammy links Count",
                value: spamLinks.length,
                max: Array.isArray(selectedTemplate.linkCheck)
                    ? selectedTemplate.linkCheck.length === 0
                        ? 0
                        : selectedTemplate.linkCheck.length - 1
                    : 0,
                color: "yellow",
            },
            {
                label: "Spammy word count",
                value: spamWordsCount,
                max: (selectedTemplate.originalContent?.body.match(/\w+/g) || []).length,
                color: "orange",
            },
            {
                label: "Spammy signature Count",
                value: spamSignature.length,
                max: Array.isArray(selectedTemplate.signature)
                    ? selectedTemplate.signature.length === 0
                        ? 0
                        : selectedTemplate.signature.length - 1
                    : 0,
                color: "green",
            },
            {
                label: "Spammy image count",
                value: spamImage.length,
                max: Array.isArray(selectedTemplate.imageCheck)
                    ? selectedTemplate.imageCheck.length === 0
                        ? 0
                        : selectedTemplate.imageCheck.length - 1
                    : 0,
                color: "red",
            },
        ];

        return spamAnalysis;
    };
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters for regex
    }
    const highlightSpamLinks = (body, subject, linkCheck, spamWord, imageCheck, signature) => {
        if ((!body && !subject) || (!linkCheck && !spamWord && !imageCheck && !signature)) return { body, subject };

        const highlightContent = (content) => {
            if (!content) return content;
            if (Array.isArray(linkCheck)) {
                linkCheck.forEach((link) => {
                    if (link.status === "spam") {
                        const linkRegex = new RegExp(link.link, "g");

                        // Regex to check if the link is within an <a> tag
                        const anchorTagRegex = new RegExp(
                            `<a[^>]*href=["']${escapeRegExp(link.link)}["'][^>]*>.*?</a>`,
                            "gi"
                        );
                        console.log(linkRegex, anchorTagRegex, "linkregexx");
                        // If the link is inside an <a> tag
                        if (anchorTagRegex.test(content)) {
                            content = content.replace(anchorTagRegex, (match) => {
                                // Modify the href or apply styles directly to the <a> tag
                                return match.replace(
                                    new RegExp(`href=["']${escapeRegExp(link.link)}["']`, "g"),
                                    `href="${link.link}" style="background-color: yellow; color: black; text-decoration: underline;"`
                                );
                            });
                        } else {
                            // If the link is not inside an <a> tag, wrap it in a span
                            const highlightLinkRegex = new RegExp(
                                `<span style="background-color: yellow; color: black; text-decoration: underline;">${escapeRegExp(link.link)}</span>`,
                                "g"
                            );

                            if (!highlightLinkRegex.test(content)) {
                                content = content.replace(
                                    linkRegex,
                                    `<span style="background-color: yellow; color: black; text-decoration: underline;">${link.link}</span>`
                                );
                                console.log(
                                    `<span style="background-color: yellow; color: black; text-decoration: underline;">${link.link}</span>`
                                );
                            }
                        }
                    }
                });
            }
            if (Array.isArray(imageCheck)) {
                imageCheck.forEach((img) => {
                    if (img.status === "spam") {
                        const imageRegex = new RegExp(`<img[^>]*src=["']${escapeRegExp(img.image)}["'][^>]*>`, "gi");

                        content = content.replace(imageRegex, (match) => {
                            if (/style=["']([^"']*)["']/.test(match)) {
                                return match.replace(/style=["']([^"']*)["']/, (styleMatch, styles) => {
                                    if (!styles.includes("border: 3px solid red")) {
                                        return `style="${styles}; border: 3px solid red;"`;
                                    }
                                    return styleMatch;
                                });
                            } else {
                                return match.replace("<img", '<img style="border: 3px solid red;"');
                            }
                        });
                    }
                });
            }

            if (Array.isArray(signature)) {
                signature.forEach((sign) => {
                    if (sign.status === "spam") {
                        const signRegex = new RegExp(escapeRegExp(sign.signature), "gi");
                        const highlightRegex = new RegExp(
                            `<span style="background-color: green; color: black; text-decoration: underline;">${escapeRegExp(sign.signature)}</span>`,
                            "gi"
                        );
                        if (!highlightRegex.test(content)) {
                            content = content.replace(
                                signRegex,
                                `<span style="background-color: green; color: black; text-decoration: underline;">${sign.signature}</span>`
                            );
                        }
                    }
                });
            }

            if (typeof spamWord === "object" && Object.keys(spamWord).length > 0) {
                spamWord.analysis.spammy_words_and_alts.forEach((item) => {
                    const spamWord =
                        item && (item.spammy || item.spammy_word)
                            ? item.spammy || item.spammy_word
                            : "No spam word found";

                    const escapedSpamWord = escapeRegExp(spamWord);
                    const wordRegex = new RegExp(`(?<!<[^>]*?)\\b${escapedSpamWord}\\b(?![^<]*?>)`, "gi");
                    console.log(wordRegex, "WordRegex");

                    const highlightWordRegex = new RegExp(
                        `<span style="color: black; background-color: #FFA500; padding: 2px; border-radius: 3px;">${escapeRegExp(
                            spamWord
                        )}</span>`,
                        "gi"
                    );
                    console.log(highlightWordRegex, "highlightWordRegex");

                    if (!highlightWordRegex.test(content)) {
                        console.log("I am una57yzethgracfd");
                        const updatedContent = content.replace(
                            wordRegex,
                            `<span style="color: black; background-color: #FFA500; padding: 2px; border-radius: 3px;">${spamWord}</span>`
                        );
                        if (updatedContent !== content) {
                            console.log("Highlight applied for:", spamWord);
                        } else {
                            console.log("No matches found for:", spamWord);
                        }
                        content = updatedContent;
                    }
                });
            }
            return content;
        };

        const highlightedBody = highlightContent(body);
        const highlightedSubject = highlightContent(subject);

        return { body: highlightedBody, subject: highlightedSubject };
    };

    // Get the spam analysis data for the selected template
    const spamAnalysisData = generateSpamAnalysis(selectedTemplate);
    const bodyWithHighlightedLinks = highlightSpamLinks(
        selectedTemplate.originalContent?.body,
        selectedTemplate.originalContent?.subject,
        selectedTemplate.linkCheck,
        selectedTemplate.spamWord,
        selectedTemplate.imageCheck,
        selectedTemplate.signature
    );
    console.log(spamAnalysisData, "spamAnalysisData");

    return (
        <Paper
            sx={{
                border: "1px solid #f1f1f1",
                borderRadius: "5px",
                backgroundColor: "#fff",
                boxShadow: "none !important",
            }}
        >
            {templates.length > 1 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {templates.length > 5 && (
                        <IconButton onClick={scrollLeft} style={{ marginTop: "-25px" }}>
                            <ArrowBackIosIcon height={20} />
                        </IconButton>
                    )}
                    <Box
                        ref={scrollRef}
                        sx={{
                            display: "flex",
                            overflowX: "hidden",
                            width: "100%",
                        }}
                    >
                        {extractedData.map((template, index) => {
                            const subject = template.originalContent?.subject || "No Subject";
                            return (
                                <ListItem
                                    key={index}
                                    className="template-card"
                                    onClick={() => handleTemplateSelect(template)}
                                    sx={{
                                        backgroundColor: "white",
                                        "&:hover": {
                                            backgroundColor: "#e0f2f1",
                                        },
                                        flex: "0 0 auto",
                                        width: "120px",
                                        margin: 2,
                                        padding: 2,
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        border: "1px solid #ccc",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <ListItemText
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography>
                                            {subject ? `${subject.split(" ")[0]}...` : "No Subject"}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                    </Box>
                    {templates.length > 5 && (
                        <IconButton onClick={scrollRight} style={{ marginTop: "-25px" }}>
                            <ArrowForwardIosIcon style={{ height: "20px" }} />
                        </IconButton>
                    )}
                </Box>
            )}
            <Grid container>
                <Grid item xs={7}>
                    <Box
                        sx={{
                            display: "flex",
                            borderRadius: "5px",
                            padding: "10px",
                            marginBottom: "20px",
                            backgroundColor: "white",
                            alignItems: "center",
                            height: "40px",
                            gap: 2,
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: "bold",
                                color: "#24a0ed",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Subject:
                        </Typography>
                        {selectedTemplate && (
                            <span
                                style={{
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    flexGrow: 1,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: bodyWithHighlightedLinks.subject || "No Content",
                                }}
                            ></span>
                        )}
                        <IconButton
                            onClick={() => handleCopy(selectedTemplate.originalContent?.subject || "No Subject")}
                        >
                            <ContentCopyIcon sx={{ marginLeft: "auto", cursor: "pointer", float: "right" }} />
                        </IconButton>
                    </Box>

                    {/* Body Display Box */}
                    <Box
                        sx={{
                            borderRadius: "5px",
                            padding: "10px",
                            backgroundColor: "white",
                            alignItems: "center",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                height: "300px",
                                overflowY: "auto",
                                padding: "10px",
                                backgroundColor: "#f9f9f9",
                                borderRadius: "5px",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: bodyWithHighlightedLinks.body || "No Content",
                            }}
                        ></div>
                        <IconButton onClick={() => handleCopy(selectedTemplate.originalContent?.body || "No Content")}>
                            <ContentCopyIcon sx={{ marginLeft: "auto", cursor: "pointer", float: "right" }} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        padding: "20px",
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Spam Analysis
                    </Typography>
                    {spamAnalysisData.map((spam, index) => {
                        const scalingFactor = Math.max(1, Math.ceil(spam.max / 1000)); // Scale down if max is too large

                        const scaledValue = spam.value / scalingFactor;
                        const scaledMax = spam.max / scalingFactor;
                        let progressValue = spam.max > 0 ? (scaledValue / scaledMax) * 100 : 0;
                        if (progressValue > 0 && progressValue < 3) {
                            progressValue = 10;
                        }
                        console.log(scaledValue, scaledMax, progressValue, spam, "dataaaaaaaaaaa");
                        return (
                            <Box key={index} sx={{ mb: 2 }}>
                                <Tooltip
                                    title={
                                        <>
                                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                {spam.label}
                                            </Typography>
                                            {spam.label !== "Subject Count" && spam.label !== "Body Count" && (
                                                <Typography variant="body2">Spam Count : {spam.value}</Typography>
                                            )}
                                            <Typography variant="body2">Total Count : {spam.max}</Typography>
                                        </>
                                    }
                                    arrow
                                >
                                    <Box>
                                        <Typography sx={{ mb: 0.5 }}>
                                            {spam.label} {":"} {spam.value}
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={progressValue}
                                            sx={{
                                                height: 10,
                                                borderRadius: 4,
                                                backgroundColor: "#e0e0e0",
                                                "& .MuiLinearProgress-bar": {
                                                    backgroundColor: spam.color,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                        );
                    })}
                </Grid>
            </Grid>
            <SnackBar
                open={successSnackbarOpen}
                message={successMessage}
                type="success"
                onClose={() => setSuccessSnackbarOpen(false)}
            />
        </Paper>
    );
};

export default TemplateList;
