import { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import MarketingCampaign from "./MarketingPlatFormReport";
import CampaignTableSkeleton from "../TableSkeleton";
import SnackBar from "../../common/SnackBar";
import MarketingPlatformConnection from "../Platforms";
const Platform = ({ secondParam, subParams }) => {
    const workctx = useContext(WorkspaceContext);
    const [marketingPlatformTable, setMarketingPlatformTable] = useState(false);
    const [showRunAudit, setShowRunAudit] = useState(false);
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(true);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const fetchCampaign = () => {
        setLoader(true);
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/retrieveCampaignData?workspace_id=${workctx.activeWorkspace}&report_type=mail_chimp`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success && data.data.length > 0) {
                    setRows(data.data);
                    setMarketingPlatformTable(true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoader(false));
    };
    const handleAuditForm = () => {
        setMarketingPlatformTable(false);
        setShowRunAudit(true);
    };

    useEffect(() => {
        if (!secondParam && !subParams) {
            fetchCampaign();
        } else {
            setLoader(false);
        }
    }, []);

    if (loader) {
        return <CampaignTableSkeleton />;
    }
    const handleShowPlatformCampaignList = () => {
        setShowRunAudit(false);
        fetchCampaign();
    };
    return (
        <Box>
            <Box>
                {marketingPlatformTable && !showRunAudit ? (
                    <MarketingCampaign rows={rows} handleAuditForm={handleAuditForm} />
                ) : (
                    <MarketingPlatformConnection
                        secondParam={secondParam}
                        subParams={subParams}
                        showPlatformCampaignList={handleShowPlatformCampaignList}
                    />
                )}
            </Box>

            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};
export default Platform;
