import React, { useState, useEffect, useContext } from "react";
import {
    TextField,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    ListItemText,
    Typography,
    IconButton,
    Dialog,
    DialogContent,
    Skeleton,
} from "@mui/material";
import HtmlPreview from "../../../AuditReportOverview/HtmlPreview";
import { WorkspaceContext } from "../../../Provider/WorkspaceProvider";
import CodeIcon from "@mui/icons-material/Code";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailOnboard from "../../../EmailOnBoard/Onboard";
import CustomBreadCrumbs from "../../../common/CustomBreadCrumbs";

const AuditForm = ({ secondParam, senderList, onSubmit, handleBack, handleImapClose, rows, selectedRow }) => {
    const workctx = useContext(WorkspaceContext);
    const [clientData, setClientData] = useState({
        campaignName: "",
        sender: [],
        subject: "",
        body: "",
    });
    const [loader, setLoader] = useState(true);
    const [errors, setErrors] = useState({
        campaignName: "",
        sender: "",
        subject: "",
        body: "",
    });
    const [showPreview, setShowPreview] = useState(true);
    const [showEmailIntegration, setShowEmailIntegration] = useState(false);

    const isSubmitDisabled = !clientData.subject.trim() || !clientData.body.trim() || clientData.sender.length === 0;

    const validateField = (name, value) => {
        // if (name === "campaignName") {
        //     if (value.trim() === "") return "Campaign Name is required";
        //     if (rows.some((row) => row.campaign_name === value)) {
        //         return "Campaign Name already exists";
        //     }
        // }
        if (name === "subject" && value.trim() === "") return "Subject is required";
        if (name === "body" && value.trim() === "") return "Body is required";
        if (name === "sender" && value.length === 0) return "At least one email must be selected";
        return "";
    };

    const handleChange = (data, name) => {
        const error = validateField(name, data);
        setErrors((prev) => ({ ...prev, [name]: error }));

        setClientData((prev) => ({ ...prev, [name]: data }));
    };

    const handleAddNewSender = () => {
        setShowEmailIntegration(true);
    };

    const handleSubmit = async () => {
        const errors = {
            campaignName: validateField("campaignName", clientData.campaignName),
            subject: validateField("subject", clientData.subject),
            body: validateField("body", clientData.body),
            sender: validateField("sender", clientData.sender),
        };

        if (Object.values(errors).some((error) => error)) {
            setErrors(errors); // Show errors if any
        } else {
            onSubmit({ ...clientData, selectedDays: null });
        }
    };

    useEffect(() => {
        setLoader(true);
        fetch(
            `${process.env.REACT_APP_SERVER_URL}/mailchimp/campaign/details?campaignId=${selectedRow.id || secondParam}&workspace_id=${workctx.activeWorkspace}`,
            {
                headers: {
                    Accept: "application/json",
                },
                credentials: "include",
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.success && data.data) {
                    const newData = {
                        campaignName: selectedRow.campaignName,
                        sender: [],
                        subject: data.data?.subject || "No Subject", // Ensure the subject exists
                        body: data.data.htmlContent || "No Body", // Ensure htmlContent exists
                    };
                    setClientData(newData);
                }
            })
            .catch((error) => {
                console.error("Error fetching campaign details:", error);
            })
            .finally(() => setLoader(false));
    }, [selectedRow, workctx.activeWorkspace]); // Make sure dependencies are properly set

    if (loader) {
        return (
            <Box sx={{ padding: 3 }}>
                <Skeleton variant="text" width="30%" height={40} />
                <Skeleton variant="rectangular" width="100%" height={56} sx={{ marginY: 2 }} />
                <Skeleton variant="text" width="30%" height={40} />
                <Skeleton variant="rectangular" width="100%" height={200} sx={{ marginY: 2 }} />
                <Skeleton variant="text" width="20%" height={40} />
                <Skeleton variant="rectangular" width="100%" height={50} sx={{ marginY: 2 }} />
                <Skeleton variant="rectangular" width="200px" height={50} sx={{ marginY: 2, marginLeft: "auto" }} />
            </Box>
        );
    }

    return (
        <>
            {showEmailIntegration && (
                <Dialog open={showEmailIntegration} maxWidth="xl" onClose={() => setShowEmailIntegration(false)}>
                    <DialogContent>
                        <EmailOnboard
                            onClose={() => setShowEmailIntegration(false)}
                            selectedButton={`email-audit-v1?tab=marketing,id=${selectedRow.id}`}
                            handleImapClose={handleImapClose}
                        />
                    </DialogContent>
                </Dialog>
            )}

            <Box>
                <CustomBreadCrumbs
                    actions={[
                        { title: "Campaign List", onClick: handleBack },
                        { title: "Audit Form", onClick: null },
                    ]}
                />
                <Box marginBottom={2}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h5" fontWeight={600}>
                            Run Your Test Audit
                        </Typography>
                        <Typography>
                            Fill in the details below to perform a comprehensive audit of your email.
                        </Typography>
                    </div>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 3,
                            marginTop: 3,
                        }}
                    >
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="from-label">Sender Email</InputLabel>
                            <Select
                                labelId="from-label"
                                value={clientData.sender}
                                onChange={(e) => handleChange(e.target.value, "sender")}
                                input={<OutlinedInput label="Sender Email" />}
                            >
                                {senderList && senderList.length > 0 ? (
                                    senderList.map((list) => (
                                        <MenuItem key={list.id} value={list.mail}>
                                            {list.mail}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        <ListItemText primary="No Data Found" />
                                    </MenuItem>
                                )}
                            </Select>
                            {errors.sender && <Box color="error.main">{errors.sender}</Box>}
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}>
                        <Typography
                            onClick={handleAddNewSender} // Function to handle adding a new sender
                            sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontSize: "0.9rem",
                            }}
                        >
                            + Add new sender
                        </Typography>
                    </Box>
                    <Typography variant="h6" mt={3}>
                        Email Template
                    </Typography>
                    <TextField
                        fullWidth
                        label="Enter email subject here"
                        value={clientData.subject}
                        onChange={(e) => handleChange(e.target.value, "subject")}
                        disabled
                        margin="normal"
                        variant="outlined"
                        error={!!errors.subject}
                        helperText={errors.subject}
                    />
                    <Box
                        sx={{
                            position: "relative",
                        }}
                    >
                        {!showPreview ? (
                            <TextField
                                fullWidth
                                label="Enter email message here"
                                value={clientData.body}
                                onChange={(e) => handleChange(e.target.value, "body")}
                                margin="normal"
                                variant="outlined"
                                disabled
                                multiline
                                rows={10}
                                error={!!errors.body}
                                helperText={errors.body}
                            />
                        ) : clientData.body.trim() ? (
                            <HtmlPreview htmlContent={clientData.body} />
                        ) : (
                            <Box
                                mt={2}
                                color="text.secondary"
                                sx={{ textAlign: "center", border: "1px solid #ccc" }}
                                height="300px"
                            >
                                No content to preview.
                            </Box>
                        )}
                        <IconButton
                            onClick={() => setShowPreview(!showPreview)}
                            sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                zIndex: 1,
                                color: showPreview ? "primary.main" : "text.secondary",
                            }}
                        >
                            {showPreview ? <VisibilityOffIcon /> : <CodeIcon />}
                        </IconButton>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AuditForm;
