import { useState, useRef, useMemo, useLayoutEffect } from "react";
import {
    Menu,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableBody,
    TableCell,
    MenuItem,
    tableCellClasses,
    Skeleton,
    Pagination,
    Chip,
    TableSortLabel,
    Tooltip,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: "bold",
        padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "18px",
        padding: "12px",
    },
}));
const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    "& .MuiTableSortLabel-icon": {
        opacity: 1, // Force the icon to always be visible
        color: theme.palette.common.white,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
        backgroundColor: "#f1f1f1 !important",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const TableComponent = ({
    rows = [],
    header = [],
    actions = [],
    isLoading = false,
    statusField,
    statusColor,
    onClick,
    columns = [],
    usePaper = true,
}) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [actionMenu, setActionMenu] = useState(null);
    const [openAction, setOpenAction] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [page, setPage] = useState(1);
    const [tooltipData, setTooltipData] = useState({});
    const textRefs = useRef({}); // Initialize as an empty object for dynamic indexing
    const rowsPerPage = 10;
    const handleActionMenu = (event, row) => {
        setActionMenu(event.currentTarget);
        setOpenAction(true);
        setSelectedRow(row);
    };

    const handleActionClose = () => {
        setActionMenu(null);
        setOpenAction(false);
        setSelectedRow(null);
    };

    const handlePageChange = (event, value) => {
        setPage(value); // Update current page
    };

    const handleSort = (column) => {
        const isAsc = orderBy === column && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(column);
    };
    const sortedData = useMemo(() => {
        return [...rows].sort((a, b) => {
            if (orderBy) {
                const valueA = a[orderBy];
                const valueB = b[orderBy];
                if (valueA === undefined || valueA === null || valueB === undefined || valueB === null) return 0;

                if (typeof valueA === "string" && typeof valueB === "string") {
                    return order === "asc"
                        ? valueA.toLowerCase().localeCompare(valueB.toLowerCase())
                        : valueB.toLowerCase().localeCompare(valueA.toLowerCase());
                }
                if (typeof valueA === "number" && typeof valueB === "number") {
                    return order === "asc" ? valueA - valueB : valueB - valueA;
                }
            }
            return 0;
        });
    }, [rows, order, orderBy]);

    const paginatedRows = useMemo(() => {
        return sortedData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    }, [sortedData, page, rowsPerPage]);

    useLayoutEffect(() => {
        const newTooltipData = {};
        paginatedRows.forEach((row, rowIndex) => {
            Object.keys(row).forEach((key, cellIndex) => {
                const refKey = `${rowIndex}-${cellIndex}`;
                const currentRef = textRefs.current[refKey];
                if (currentRef) {
                    const { scrollWidth, clientWidth } = currentRef;
                    newTooltipData[refKey] = scrollWidth > clientWidth;
                }
            });
        });
        setTooltipData(newTooltipData);
    }, [paginatedRows]);
    return (
        <>
            <TableContainer component={usePaper ? Paper : "div"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {header.length > 0 &&
                                header.map((data, index) => <StyledTableCell key={index}>{data}</StyledTableCell>)}
                            {columns.length > 0 &&
                                columns.map((column) => (
                                    <StyledTableCell
                                        key={column.field}
                                        sortDirection={orderBy === column.field ? order : false}
                                    >
                                        {column.sortable ? (
                                            <StyledTableSortLabel
                                                active={orderBy === column.field}
                                                direction={orderBy === column.field ? order : "asc"}
                                                onClick={() => handleSort(column.field)}
                                            >
                                                {column.headerName}
                                            </StyledTableSortLabel>
                                        ) : (
                                            column.headerName
                                        )}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            Array.from({ length: 4 }).map((_, rowIndex) => (
                                <StyledTableRow key={rowIndex}>
                                    {Array.from({ length: columns.length > 0 ? columns.length : header.length }).map(
                                        (_, cellIndex) => (
                                            <StyledTableCell key={cellIndex}>
                                                <Skeleton animation="wave" height={30} />
                                            </StyledTableCell>
                                        )
                                    )}
                                </StyledTableRow>
                            ))
                        ) : rows.length > 0 ? (
                            paginatedRows.map((row, index) => (
                                <StyledTableRow
                                    key={index}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClick && onClick(row);
                                    }}
                                    sx={{
                                        cursor: onClick ? "pointer" : "default",
                                    }}
                                >
                                    {Object.keys(row)
                                        .filter((key) => key !== "id")
                                        .map((key, cellIndex) => {
                                            const refKey = `${index}-${cellIndex}`;
                                            return (
                                                <StyledTableCell key={cellIndex}>
                                                    {statusField === key ? (
                                                        <Chip
                                                            label={row[key]}
                                                            color={statusColor[row[key]] || "default"} // Map status to color
                                                            variant="contained"
                                                        />
                                                    ) : (
                                                        <Tooltip
                                                            title={tooltipData[refKey] ? row[key] : ""}
                                                            placement="bottom"
                                                            arrow
                                                            disableInteractive
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        backgroundColor: "#ffffff", // White background
                                                                        color: "#000000", // Black text
                                                                        border: "1px solid #ddd",
                                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                                        fontSize: "0.875rem", // Adjust font size
                                                                    },
                                                                },
                                                                arrow: {
                                                                    sx: {
                                                                        color: "#ffffff", // Match the tooltip's background
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <span
                                                                ref={(el) => {
                                                                    textRefs.current[refKey] = el;
                                                                }}
                                                                style={{
                                                                    display: "inline-block",
                                                                    maxWidth: "200px",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {row[key]}
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </StyledTableCell>
                                            );
                                        })}
                                    {actions.length > 0 && (
                                        <StyledTableCell>
                                            {actions.length > 0 && (
                                                <MoreVertIcon
                                                    id={`action-icon-${index}`}
                                                    aria-controls={
                                                        openAction && selectedRow === row ? "basic-menu" : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    aria-expanded={
                                                        openAction && selectedRow === row ? "true" : undefined
                                                    }
                                                    onClick={(event) => {
                                                        event.stopPropagation(); // Prevent row click propagation
                                                        handleActionMenu(event, row);
                                                    }}
                                                    sx={{
                                                        cursor: "pointer",
                                                        fontWeight: "bold",
                                                    }}
                                                />
                                            )}
                                            {selectedRow === row && (
                                                <Menu
                                                    id={`menu-${index}`}
                                                    anchorEl={actionMenu}
                                                    open={openAction}
                                                    onClose={(e) => {
                                                        e.stopPropagation(); // Prevent menu click propagation
                                                        handleActionClose();
                                                    }}
                                                    MenuListProps={{
                                                        "aria-labelledby": `action-icon-${index}`,
                                                    }}
                                                >
                                                    {actions.map((action, actionIndex) => (
                                                        <MenuItem
                                                            key={actionIndex}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                action.click(selectedRow);
                                                                handleActionClose();
                                                            }}
                                                        >
                                                            {action.title}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            )}
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={columns.length} align="center">
                                    No Data Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {rows.length > 10 && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "16px",
                    }}
                >
                    <Pagination
                        count={Math.ceil(rows.length / rowsPerPage)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        variant="outlined"
                    />
                </Box>
            )}
        </>
    );
};

export default TableComponent;
