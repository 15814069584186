import { useState, useEffect, useMemo } from "react";
import TableComponent from "../../common/TableComponent";
import { Box, Button, TextField, InputAdornment, IconButton, Select, MenuItem } from "@mui/material";
import FetchReport from "../../AuditReportOverview/FetchCampaignReport";
import CreateReport from "../../AuditReportOverview/CreateCampaignReport";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
const MarketingCampaign = ({ handleAuditForm, rows }) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [viewReport, setViewReport] = useState(false);
    const [loader, setLoader] = useState(false);
    const [noDataCount, setNoDataCount] = useState(0);

    const [toolsFilter, setToolsFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [selectedRow, setSelectedRow] = useState([]);
    const [regenerate, setRegenerate] = useState(false);
    const [key, setKey] = useState(0);
    const columns = [
        { field: "campaignName", headerName: "Campaign Name", sortable: true },
        { field: "tools", headerName: "Tools", sortable: true },
        { field: "created", headerName: "Created", sortable: true },
    ];
    const handleViewReport = (row) => {
        const found = rows.find((item) => item.process_code === row.id);
        if (found && found.status !== "Failed") {
            setSelectedRow(found);
            setViewReport(true);
        }
    };
    const handleBack = () => {
        navigate(`/dashboard/email-audit-v1?tab=marketing`, { replace: true });
        setViewReport(false);
    };
    const filteredSender = useMemo(() => {
        const updatedRows = rows.map((item) => ({
            id: item.process_code,
            campaignName: item.campaign_name || "NA",
            tools: item.report_type === "mail_chimp" ? "mailchimp" : item.report_type || "NA",
            created: item?.createdAt ? new Date(item.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-") : "NA",
        }));
        console.log(updatedRows, "updatedRowsssssssssss");

        return updatedRows.filter((campaignList) => {
            const matchesSearch = searchText
                ? campaignList.campaignName.toLowerCase().includes(searchText.toLowerCase())
                : true;
            const matchesDate = selectedDate ? dayjs(selectedDate).format("DD-MM-YYYY") === campaignList.created : true;
            const matchesStatus = statusFilter ? campaignList.status === statusFilter : true;
            const matchesTools = toolsFilter ? campaignList.tools === toolsFilter : true;
            return matchesSearch && matchesDate && matchesStatus && matchesTools;
        });
    }, [rows, searchText, selectedDate, statusFilter, toolsFilter]);
    useEffect(() => {
        console.log(filteredSender, "i am filtereds sender");
        if (filteredSender.length > 0) {
            setLoader(false);
        } else {
            setNoDataCount((prev) => {
                const newCount = prev + 1;
                if (newCount >= 3) {
                    setLoader(false);
                }
                return newCount;
            });
        }
    }, [filteredSender]);
    const handleRegenerate = () => {
        console.log("regenrateclicked");
        setRegenerate(true);
        setKey((prev) => prev + 1);
    };
    return (
        <Box key={key}>
            {viewReport ? (
                <Box>
                    {regenerate ? (
                        <CreateReport
                            regenerate={regenerate}
                            clientData={selectedRow}
                            handleBackReport={handleBack}
                            actions={[
                                { title: "Campaign List", onClick: handleBack },
                                { title: "Report", onClick: null },
                            ]}
                            handleRegenerate={handleRegenerate}
                            type="mail_chimp"
                        />
                    ) : (
                        <FetchReport
                            handleBack={handleBack}
                            processCode={selectedRow.process_code}
                            actions={[
                                { title: "Campaign List", onClick: handleBack },
                                { title: "Report", onClick: null },
                            ]}
                            //handleRegenerate={handleRegenerate}
                            reportData={selectedRow}
                            type="mail_chimp"
                        />
                    )}
                </Box>
            ) : (
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleAuditForm}>
                            Run Test Audit
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 2,
                            mb: 3,
                            flexWrap: "wrap",
                        }}
                    >
                        <TextField
                            placeholder="Search by Campaign Name"
                            size="small"
                            variant="outlined"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: "300px" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <Select
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                                displayEmpty
                                size="small"
                                sx={{ minWidth: 150 }}
                            >
                                <MenuItem value="">All Status</MenuItem>
                                <MenuItem value="Draft">Draft</MenuItem>
                                <MenuItem value="In Progress">In Progress</MenuItem>
                                <MenuItem value="Sent">Sent</MenuItem>
                            </Select>
                            <Select
                                value={toolsFilter}
                                onChange={(e) => setToolsFilter(e.target.value)}
                                displayEmpty
                                size="small"
                                sx={{ minWidth: 150 }}
                            >
                                <MenuItem value="">All Tools</MenuItem>
                                <MenuItem value="mailchimp">Mail Chimp</MenuItem>
                                <MenuItem value="sendgrid">Send Grid</MenuItem>
                                <MenuItem value="amazon">Amazon</MenuItem>
                            </Select>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                {showDatePicker ? (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={selectedDate}
                                            onChange={(newDate) => setSelectedDate(newDate)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    placeholder="Select Date"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton>
                                                                <DateRangeIcon />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                ) : (
                                    <IconButton onClick={() => setShowDatePicker(true)}>
                                        <DateRangeIcon />
                                    </IconButton>
                                )}
                                {(selectedDate || showDatePicker) && (
                                    <IconButton
                                        onClick={() => {
                                            setSelectedDate(null);
                                            setShowDatePicker(false);
                                        }}
                                        color="primary"
                                        title="Clear Date"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <TableComponent
                        rows={filteredSender}
                        columns={columns}
                        isLoading={loader}
                        onClick={(row) => handleViewReport(row)}
                    />
                </Box>
            )}
        </Box>
    );
};
export default MarketingCampaign;
