import { useState, useEffect } from "react";
import { Box, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import TableComponent from "../../common/TableComponent";
import ManualEmailAuditPage from "./EmailAccountForm";
import { CircularProgress } from "@material-ui/core";
import FetchReport from "../../AuditReportOverview/FetchCampaignReport";
import CreateReport from "../../AuditReportOverview/CreateCampaignReport";
import { useNavigate } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import SnackBar from "../../common/SnackBar";
import CampaignTableSkeleton from "../TableSkeleton";
const EmailAccount = ({ handleBackReport, rows, secondParam }) => {
    console.log(secondParam, "secondParams");
    const navigate = useNavigate();
    const [showManualEmailAuditForm, setShowManualEmailAuditForm] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [viewReport, setViewReport] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [key, setKey] = useState(0);
    const [regenerate, setRegenerate] = useState(false);
    const [sender, setSender] = useState("");
    const columns = [
        { field: "campaign_name", headerName: "Campaign Name", sortable: true },
        { field: "sender", headerName: "Sender", sortable: true },
        { field: "inbox", headerName: "Inbox", sortable: true },
        { field: "spam", headerName: "Spam", sortable: true },
        { field: "created", headerName: "Created", sortable: true },
        { field: "action", headerName: "Actions", sortable: false },
    ];
    const Actions = [
        {
            title: "View Report",
            click: (row) => {
                handleViewReport(row);
            },
        },
    ];
    const updatedRows = rows
        ? rows.map((item) => {
              const iptCounts = item?.ipt_counts ? JSON.parse(item.ipt_counts) : {};
              return {
                  campaign_name: item.campaign_name || "NA",
                  sender: item?.sender || "NA",
                  inbox: iptCounts.overallInboxCount || 0,
                  spam: iptCounts.overallSpamCount || 0,
                  created: item?.createdAt
                      ? new Date(item.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-")
                      : "NA",
              };
          })
        : [];
    const filteredSender = updatedRows.filter((campaignList) => {
        const matchesSearch = searchText
            ? campaignList.campaign_name.toLowerCase().includes(searchText.toLowerCase()) ||
              campaignList.sender.toLowerCase().includes(searchText.toLowerCase())
            : true;
        const matchesDate = selectedDate
            ? dayjs(selectedDate).format("DD-MM-YYYY") ===
              dayjs(campaignList.created, "DD-MM-YYYY").format("DD-MM-YYYY")
            : true;

        return matchesSearch && matchesDate;
    });
    const handleViewReport = (row) => {
        console.log(row, "report");
        const found = rows.find((item) => item.campaign_name === row.campaign_name);
        if (found && found.status.toLowerCase() !== "failed") {
            setSelectedRow(found);
            setViewReport(true);
        }
    };
    const fetchRowByCampaign = async (campaign) => {
        console.log(campaign, rows, "filter rows by campaign");
        const filterCampaignRow = await rows.filter((record) => record.campaign_name === secondParam);
        console.log(filterCampaignRow, "filterCampaignnn");
        if (filterCampaignRow.length > 0) {
            setSelectedRow(filterCampaignRow[0]);
            handleViewReport(filterCampaignRow[0]);
        } else {
            setErrorSnackbarOpen(true);
            setErrorMessage("No Record found for this campaign.");
        }
        setDataFetched(true); // Mark data as fetched
    };
    useEffect(() => {
        if (secondParam) {
            fetchRowByCampaign(secondParam);
        } else {
            setDataFetched(true); // Mark data as fetched
        }
    }, [secondParam, rows]);
    const handleAuditForm = () => {
        setShowManualEmailAuditForm(true);
    };
    const handleBack = () => {
        navigate("/dashboard/email-audit-v1?tab=email", { replace: true });
        setRegenerate(false);
        handleBackReport();
    };
    if (!dataFetched) {
        return <CampaignTableSkeleton />;
    }
    const handleRegenerate = (sender) => {
        console.log(sender, "regenrateclicked");
        setSender(sender);
        setRegenerate(true);
        setKey((prev) => prev + 1);
    };
    console.log(selectedRow, "selectedRowww", updatedRows);
    return (
        <Box key={key}>
            {viewReport ? (
                <Box>
                    {secondParam || regenerate ? (
                        <CreateReport
                            regenerate={regenerate}
                            clientData={selectedRow}
                            selectedButton={`email-audit-v1?tab=email,campaign=${selectedRow.campaign_name}`}
                            handleBackReport={handleBack}
                            actions={[
                                { title: "Email Accounts", onClick: handleBack },
                                { title: "Report", onClick: null },
                            ]}
                            secondParam={secondParam}
                            handleRegenerate={(sender) => handleRegenerate(sender)}
                            sender={sender}
                            type="manual_audit"
                        />
                    ) : (
                        <FetchReport
                            selectedButton={`email-audit-v1?tab=email,campaign=${selectedRow.campaign_name}`}
                            handleBack={handleBack}
                            actions={[
                                { title: "Email Accounts", onClick: handleBack },
                                { title: "Report", onClick: null },
                            ]}
                            type="manual_audit"
                            handleRegenerate={handleRegenerate}
                            reportData={selectedRow}
                        />
                    )}
                </Box>
            ) : (
                <Box>
                    {showManualEmailAuditForm ? (
                        <ManualEmailAuditPage
                            handleBack={handleBack}
                            handleBackReport={handleBackReport}
                            actions={[
                                { title: "Email Accounts", onClick: handleBack },
                                { title: "Report", onClick: null },
                            ]}
                            rows={rows}
                        />
                    ) : (
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleAuditForm}>
                                    Run Test Audit
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: 2,
                                    mb: 3,
                                    flexWrap: "wrap",
                                }}
                            >
                                <TextField
                                    placeholder="Search by Campaign or Sender Email"
                                    size="small"
                                    variant="outlined"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    style={{ width: "500px" }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    {showDatePicker ? (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={selectedDate}
                                                onChange={(newDate) => setSelectedDate(newDate)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder="Select Date"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <IconButton>
                                                                    <DateRangeIcon />
                                                                </IconButton>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    ) : (
                                        <IconButton onClick={() => setShowDatePicker(true)}>
                                            <DateRangeIcon />
                                        </IconButton>
                                    )}
                                    {(selectedDate || showDatePicker) && (
                                        <IconButton
                                            onClick={() => {
                                                setSelectedDate(null);
                                                setShowDatePicker(false);
                                            }}
                                            color="primary"
                                            title="Clear Date"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                            <TableComponent
                                rows={filteredSender}
                                columns={columns}
                                actions={Actions}
                                onClick={(row) => handleViewReport(row)}
                            />
                        </Box>
                    )}
                </Box>
            )}
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
};
export default EmailAccount;
