import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton } from "@mui/material";

const CampaignTableSkeleton = () => {
    return (
        <TableContainer component={Paper}>
            <Table>
                {/* Table Header */}
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: "#1976d2", color: "white" }}>
                            <Skeleton variant="text" width="20%" height={25} />
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#1976d2", color: "white" }}>
                            <Skeleton variant="text" width="20%" height={25} />
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#1976d2", color: "white" }}>
                            <Skeleton variant="text" width="20%" height={25} />
                        </TableCell>
                    </TableRow>
                </TableHead>

                {/* Table Body (Skeleton Rows) */}
                <TableBody>
                    {Array.from(new Array(3)).map((_, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Skeleton variant="text" width="80%" height={25} />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" width="50%" height={25} />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" width="60%" height={25} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CampaignTableSkeleton;
