import { useState } from "react";
import { Paper, IconButton, Typography, Button, Box, Dialog } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailOnboardDialog from "../EmailOnBoard/Onboard";
const AddIntegration = ({ handleImapClose, selectedButton = "spamwatch", senderEmail = "", disabled = false }) => {
    const [showOnboard, setShowOnboard] = useState(false);
    const handleAddIntegration = () => {
        setShowOnboard(true);
    };
    return (
        <Box>
            <Paper
                sx={{
                    margin: "auto",
                    padding: "20px",
                    textAlign: "center",
                }}
            >
                <IconButton disableRipple sx={{ fontSize: 60, color: "black" }}>
                    <MailOutlineIcon fontSize="inherit" />
                </IconButton>
                <Typography fontWeight="bold">
                    To view the template report, you should add your sender account{" "}
                    <Typography component="span" color="primary" fontWeight="bold">
                        {senderEmail}
                    </Typography>
                </Typography>

                <Button
                    variant="contained"
                    color="info"
                    sx={{ mt: 3, textTransform: "none", px: 4 }}
                    onClick={handleAddIntegration}
                    disabled={disabled}
                >
                    Add Sender Email
                </Button>
            </Paper>
            {showOnboard && (
                <Dialog open={showOnboard} onClose={() => setShowOnboard(false)} maxWidth="xl">
                    <Box sx={{ margin: "10px" }}>
                        <EmailOnboardDialog
                            onClose={() => setShowOnboard(false)}
                            selectedButton={selectedButton}
                            handleImapClose={handleImapClose}
                        />
                    </Box>
                </Dialog>
            )}
        </Box>
    );
};
export default AddIntegration;
