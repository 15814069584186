import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
    CircularProgress,
    IconButton,
} from "@mui/material";
import MailChimpAudit from "../MailChimpAudit";
import { WorkspaceContext } from "../../Provider/WorkspaceProvider";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const MarketingPlatformConnection = ({ secondParam, subParams, showPlatformCampaignList }) => {
    const workctx = useContext(WorkspaceContext);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    const [loader, setLoader] = useState(subParams ? true : false);
    const [isMailChimpConnected, setMailChimpConnected] = useState(false);
    const platforms = [
        {
            name: "Mailchimp",
            component: (
                <MailChimpAudit
                    mailchimpConnected={isMailChimpConnected}
                    secondParam={secondParam}
                    showPlatformCampaignList={showPlatformCampaignList}
                />
            ),
        },
        { name: "SendGrid", component: <Box>SendGrid</Box> },
        { name: "Amazon SES", component: <Box>Amazon SES</Box> },
        { name: "ABC", component: <Box>ABC</Box> },
        { name: "XYZ", component: <Box>XYZ</Box> },
        // Add more platforms as needed
    ];
    const [selectedPlatform, setSelectedPlatform] = useState("Mailchimp");

    const handlePlatformClick = (platformName) => {
        setSelectedPlatform(platformName);
    };

    useEffect(() => {
        const fetchMarketingData = async () => {
            if (subParams && workctx.activeWorkspace) {
                setLoader(true);
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_SERVER_URL}/oauth/mailchimp/callback?code=${code}&workspace_id=${workctx.activeWorkspace}`,
                        {
                            headers: {
                                Accept: "application/json",
                            },
                            credentials: "include",
                            method: "GET",
                        }
                    );
                    const data = await response.json();
                    if (data.success) {
                        setMailChimpConnected(true);
                    }
                } catch (error) {
                    console.error("Error fetching marketing data:", error);
                } finally {
                    setLoader(false);
                    navigate(`/dashboard/email-audit-v1?tab=marketing`, { replace: true });
                }
            }
        };

        fetchMarketingData();
    }, [subParams]);
    return (
        <Box p={3}>
            <IconButton disableRipple sx={{ fontSize: 80 }}>
                <ArrowBackIcon onClick={() => showPlatformCampaignList()} />
            </IconButton>
            <Typography variant="h6" gutterBottom>
                Connect your marketing platforms to audit your email campaigns
            </Typography>
            <Grid container spacing={2}>
                {/* Sidebar for Platform List */}
                <Grid item xs={12} sm={4}>
                    <Paper elevation={3}>
                        <List>
                            {platforms.map(({ name }) => (
                                <ListItem
                                    key={name}
                                    button
                                    selected={name === selectedPlatform}
                                    onClick={() => handlePlatformClick(name)}
                                >
                                    <ListItemText primary={name} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>

                {/* Main Content: Login Form */}
                <Grid item xs={12} sm={8}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        {loader ? (
                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <Box>{platforms.find(({ name }) => name === selectedPlatform)?.component}</Box>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MarketingPlatformConnection;
