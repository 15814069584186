import { useContext, useEffect } from "react";
import Subscriptions from "./SubscriptionPage";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Provider/AuthProvider";
const Billing = () => {
    const navigate = useNavigate();
    const { isExpiredPlan } = useContext(AuthContext);

    useEffect(() => {
        if (!isExpiredPlan) {
            navigate("/dashboard/home");
        }
    }, []);
    return <Subscriptions />;
};
export default Billing;
