import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Paper, ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import { Divider } from "rsuite";
import XAuditLogo from "../../img/icons/xaudit1.png";
import { AuthContext } from "../Provider/AuthProvider";
import { useEffect } from "react";
import SkeletonSubscription from "./SkeletonSubscription";
import SnackBar from "../common/SnackBar";
export default function Billing() {
    const planOrder = ["free", "basic", "advanced"];
    const [billingCycle, setBillingCycle] = useState("month");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [buyLoading, setBuyLoader] = useState(false);
    const [Plans, setPlans] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const { PlanDetails, isExpiredPlan, user } = useContext(AuthContext);
    console.log(user, "user");
    const handleBillingCycleChange = (event, newCycle) => {
        if (newCycle) {
            setBillingCycle(newCycle);
        }
    };
    useEffect(() => {
        setShowSkeleton(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/plain-details?interval=${billingCycle}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setPlans(data.data);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
            })
            .catch((error) => {
                console.error("Error fetching plan details:", error);
            })
            .finally(() => {
                setShowSkeleton(false);
            });
    }, [billingCycle]);
    const convertedPlans =
        Plans.length > 0
            ? Plans.map((plan) => {
                  const aduitLimit = plan.metadata?.audit_limit.split(",");
                  const dailyLimit = aduitLimit[0].split(":")[1];
                  const monthlyLimit = aduitLimit[1].split(":")[1];
                  return {
                      id: plan.id ?? "",
                      title: plan.name ?? "",
                      description: plan.description ?? "",
                      priceId: plan.price.id,
                      price:
                          plan.name === "Free"
                              ? plan.price?.unit_amount ?? 0
                              : plan.price?.interval === "month"
                                ? `$${(plan.price?.unit_amount ?? 0) / 100}/mo`
                                : `$${(plan.price?.unit_amount ?? 0) / 100}/yr`,
                      details: plan.metadata?.features?.split(",").map((feature) => feature.trim()) ?? [],
                      tools: plan.metadata?.tools?.split(",").map((tool) => tool.trim()) ?? [],
                      memberLimit: `${plan.metadata?.member_limit ?? 0} members per workspace`,
                      workspaceLimit: `${plan.metadata?.workspace_limit ?? 0} workspaces`,
                      dailyAuditLimit: `${dailyLimit ?? 0} daily audits`,
                      monthlyAuditLimit: `${monthlyLimit ?? 0} monthly audits`,
                      isCurrent: false,
                      isMostPopular: plan.name === "Advanced",
                  };
              }).filter((plan) => planOrder.includes(plan.title.toLowerCase()))
            : [];
    const sortedPlans = convertedPlans.sort((a, b) => {
        const aIndex = planOrder.indexOf(a.title.toLowerCase());
        const bIndex = planOrder.indexOf(b.title.toLowerCase());

        return aIndex - bIndex;
    });
    const handleBuyNow = (id) => {
        setBuyLoader(id);
        fetch(`${process.env.REACT_APP_SERVER_URL}/createCheckoutSession`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                priceId: id,
                emailId: user.email,
                roleId: user.role_id ? user.role_id : user.role ? user.role : null,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    console.log(data, "buynow");
                    window.location.href = data.paymentUrl;
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                }
            })
            .finally(() => setBuyLoader(false));
    };

    console.log(PlanDetails, isExpiredPlan, "subscibe", sortedPlans);
    return (
        <Box sx={{ px: 4, py: 2 }}>
            {isExpiredPlan && (
                <Box>
                    <img src={XAuditLogo} alt="XAudit Logo" style={{ maxWidth: "200px", marginBottom: "16px" }} />
                    <Box sx={{ textAlign: "center", marginBottom: 3 }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "24px" }}>
                            Hi {user.firstname || ""}, Your {PlanDetails?.planName || ""} Has Expired!
                        </Typography>
                        <Typography>We hope you were able to spend the time exploring xemailaudit</Typography>
                        <Typography>Upgrade to one of our plans to continue.</Typography>
                    </Box>
                    <Divider />
                </Box>
            )}
            <ToggleButtonGroup
                color="primary"
                value={billingCycle}
                exclusive
                onChange={handleBillingCycleChange}
                sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}
            >
                <ToggleButton value="month">Monthly</ToggleButton>
                <ToggleButton value="year">Yearly</ToggleButton>
            </ToggleButtonGroup>
            {showSkeleton ? (
                <SkeletonSubscription />
            ) : (
                <Grid container spacing={3} sx={{ justifyContent: "center", px: 4 }}>
                    {sortedPlans.length > 0 ? (
                        sortedPlans.map((plan, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        padding: "20px",
                                        minWidth: "250px",
                                        margin: "10px auto",
                                        height: "100%",
                                        position: "relative",
                                    }}
                                >
                                    {plan.isMostPopular && (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: -15,
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                bgcolor: "primary.main",
                                                color: "white",
                                                px: 2,
                                                py: 0.5,
                                                borderRadius: "10px",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Most Popular
                                        </Box>
                                    )}
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        {plan.title}
                                    </Typography>
                                    <Typography variant="h4" color="textPrimary">
                                        {plan.price}
                                    </Typography>
                                    <Typography>{plan.description}</Typography>

                                    <Button
                                        variant="contained"
                                        color={plan.isCurrent ? "secondary" : "primary"}
                                        sx={{
                                            marginTop: "10px",
                                            display:
                                                plan.title === "Free" &&
                                                (PlanDetails?.priceId !== plan.priceId || isExpiredPlan)
                                                    ? "none"
                                                    : "inline-flex",
                                        }}
                                        disabled={
                                            buyLoading === plan.priceId ||
                                            (PlanDetails?.priceId === plan.priceId && !isExpiredPlan)
                                        }
                                        onClick={() => handleBuyNow(plan.priceId)}
                                    >
                                        {plan.priceId === PlanDetails?.priceId && !isExpiredPlan
                                            ? "Current Plan"
                                            : "Buy Now"}
                                    </Button>

                                    <Typography variant="subtitle1" sx={{ marginTop: "10px" }}>
                                        Includes:
                                    </Typography>
                                    <ul style={{ paddingLeft: "20px" }}>
                                        <li>{plan.workspaceLimit}</li>
                                        <li>{plan.memberLimit}</li>
                                        <li>{plan.dailyAuditLimit}</li>
                                        <li>{plan.monthlyAuditLimit}</li>
                                        {plan.details.map((item, idx) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                    <Typography variant="subtitle1" sx={{ marginTop: "10px" }}>
                                        Tools available:
                                    </Typography>
                                    <ul style={{ paddingLeft: "20px" }}>
                                        {plan.tools.map((tool, idx) => (
                                            <li key={idx}>{tool}</li>
                                        ))}
                                    </ul>
                                </Paper>
                            </Grid>
                        ))
                    ) : (
                        <Box sx={{ textAlign: "center", mt: 4 }}>
                            <Typography variant="h6" color="textSecondary">
                                No plans found.
                            </Typography>
                        </Box>
                    )}

                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: "0.75rem",
                                textAlign: "center",
                                mt: isExpiredPlan ? 5 : 0,
                                color: "text.secondary",
                            }}
                        >
                            For custom requirements, please contact us at sales@xemailaudit.com
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <SnackBar
                open={errorSnackbarOpen}
                message={errorMessage}
                type="error"
                onClose={() => setErrorSnackbarOpen(false)}
            />
        </Box>
    );
}
