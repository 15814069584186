import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import XAuditLogo from "../../img/icons/xaudit1.png";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const InvalidVerificationAttempt = ({ title }) => {
    return (
        <Box>
            <Box component="img" src={XAuditLogo} alt="Invalid Verification" sx={{ marginBottom: 2, width: "15%" }} />
            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box style={{ textAlign: "center" }}>
                    <WarningAmberIcon sx={{ minWidth: "80px", height: "auto" }} />
                    <Typography sx={{ fontSize: "30px", fontWeight: "700", textAlign: "center" }}>
                        {title === "Email already verified" ? title : "Invalid Verification Attempt"}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        {title === "Email already verified"
                            ? "It seems that this email has already been verified. Verification links are one-time use, and this one has already been completed."
                            : "It seems that this verification link is not valid. Verification links are unique to each user, and it appears this one was intended for another account."}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ marginTop: "20px", borderTop: "1px solid #ccc", paddingTop: "10px" }}
                    >
                        If you have any questions or need assistance, feel free to contact our support team at
                        support@xemailaudit.com.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default InvalidVerificationAttempt;
