import Tabs from "./Tabs";
import { useState } from "react";
import EmailAccountHome from "./EmailAccounts/index";
import AutomaticTest from "./AutomaticTest";
import MarketingPlatform from "./Marketing";
import { useLocation, useNavigate } from "react-router-dom";
const EmailAuditV1 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    const [firstparam, secondParam] = tab ? tab.split(",") : [null, null];
    const getSecondParam = secondParam ? secondParam.split("=")[1] : null;
    const [activeTab, setActiveTab] = useState(() => {
        if (!tab) return "email";
        const firstPart = tab?.includes("/") ? tab.split("/")[0] : tab.split(",")[0];
        return firstPart;
    });
    const [subParams, setSubParams] = useState(tab ? tab.split("/").slice(1).join("/") : null);
    const handleTabChange = (newTab) => {
        navigate(`/dashboard/email-audit-v1?tab=${newTab}`);
        setActiveTab(newTab);
        setSubParams(newTab.split("/")?.slice(1).join("/") || null);
    };
    console.log(subParams, "subParams");
    console.log(firstparam, getSecondParam, activeTab, "initalTab, campaignName");
    return (
        <div>
            <div>
                <Tabs activeTab={activeTab} onTabChange={handleTabChange} />
            </div>
            {activeTab === "email" && <EmailAccountHome secondParam={getSecondParam} />}
            {activeTab === "automatic" && <AutomaticTest secondParam={getSecondParam} />}
            {activeTab === "marketing" && <MarketingPlatform secondParam={getSecondParam} subParams={subParams} />}
        </div>
    );
};

export default EmailAuditV1;
