import React, { createContext, useState, useEffect, useContext } from "react";
import jwtDecode from "jwt-decode";
import cookie from "cookiejs";
import { AuthContext } from "./AuthProvider";
const WorkspaceContext = createContext();

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WorkspaceProvider = ({ children }) => {
    const authCtx = useContext(AuthContext);
    const isLogin = cookie.get("token");
    const loggeduser = isLogin ? jwtDecode(isLogin) : {};
    const [workspaceList, setWorkspaceList] = useState([]);
    const [updateWorkspace, setUpdateWorkspace] = useState(false);
    const [activeWorkspace, setActiveWorkspace] = useState(localStorage.getItem("activeWorkspace") || null);
    const [workspaceLimit, setWorkspaceLimit] = useState({
        maxWorkspace: loggeduser.maxWorkspace || 0,
        maxTeam: loggeduser.maxTeam || 0,
        role: loggeduser.role || loggeduser.role_id || 3,
    });
    const recheckWorkspace = async () => {
        const isLogin = cookie.get("token");
        const loggeduser = isLogin ? jwtDecode(isLogin) : {};
        authCtx.handleuser(loggeduser);
        setWorkspaceLimit({
            maxWorkspace: loggeduser.maxWorkspace || 0,
            maxTeam: loggeduser.maxTeam || 0,
            role: loggeduser.role || loggeduser.role_id || 3,
        });
    };
    const handleActiveWorkspace = (id) => {
        setActiveWorkspace(id);
        localStorage.setItem("activeWorkspace", id);
    };
    const handleworkspaceLimit = (data) => {
        setWorkspaceLimit({ ...data, role: 2 });
    };
    const handleWorkspaceList = (data) => {
        setWorkspaceList(() => data);
    };
    const handleUpdateWorkspace = () => {
        setUpdateWorkspace(true);
    };
    const workspaceContextValue = {
        activeWorkspace,
        workspaceLimit,
        handleworkspaceLimit,
        recheckWorkspace,
        workspaceList,
        handleWorkspaceList,
        handleUpdateWorkspace,
        handleActiveWorkspace,
    };

    useEffect(() => {
        if (updateWorkspace) {
            const updateWorkspaceList = JSON.parse(JSON.stringify(workspaceList));
            updateWorkspaceList.forEach(
                async (workspace) =>
                    await fetch(`${REACT_APP_SERVER_URL}/fetchWorkspaceMembers/${workspace.id}`, {
                        headers: {
                            Accept: "application/json",
                        },
                        credentials: "include",
                        method: "GET",
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            if (data.data) {
                                workspace.totalMembers = data.data.length;
                            }
                        })
            );
            handleWorkspaceList(updateWorkspaceList);
            setUpdateWorkspace(false);
        }
    }, [updateWorkspace]);
    return <WorkspaceContext.Provider value={workspaceContextValue}>{children}</WorkspaceContext.Provider>;
};

export { WorkspaceContext, WorkspaceProvider };
