import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    TextField,
    Button,
    MenuItem,
    Paper,
    Divider,
    Select,
    InputLabel,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Chip,
    CircularProgress,
    Skeleton,
    Menu,
    Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AuthContext } from "../Provider/AuthProvider";
import SnackBar from "../common/SnackBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { WorkspaceContext } from "../Provider/WorkspaceProvider";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WorkspaceManagement = ({ workSpaceData, handleCloseWorkSpace }) => {
    const ctx = useContext(AuthContext);
    const workspaceContext = useContext(WorkspaceContext);
    const navigate = useNavigate();
    const [isEditable, setIsEditable] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [workspaceName, setWorkspaceName] = useState(workSpaceData.name);
    const [newMemberEmail, setNewMemberEmail] = useState("");
    const [newMemberRole, setNewMemberRole] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [members, setMembers] = useState([]);
    const [isMember, setIsMember] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [modalData, setModalData] = useState({
        content: "",
        buttonText: "",
        clickFunction: "",
    });
    const [replaceLoading, setReplaceLoading] = useState(false);
    const [actionMenu, setActionMenu] = useState(null); // Store anchor element
    const [openAction, setOpenAction] = useState(false); // Manage menu state
    const [selectedMember, setSelectedMember] = useState(null); // Store the selected member

    const handleActionMenu = (event, member) => {
        setActionMenu(event.currentTarget);
        setOpenAction(true);
        setSelectedMember(member);
    };
    const handleActionClose = () => {
        setActionMenu(null);
        setOpenAction(false);
        setSelectedMember(null);
    };
    const [workspaceLoading, setWorkspaceLoading] = useState(false);
    const handleCancelClick = () => {
        setWorkspaceName(workSpaceData.name);
        setIsEditable(false);
    };
    const handleSaveClick = () => {
        if (!workspaceName && workspaceName.trim().length < 5) {
            setOpenSnackbar({ message: "Invalid WorkSpace Name", type: "error" });
            return;
        }
        setWorkspaceLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/updateWorkspaceName`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspaceName: workspaceName,
                workspaceId: workSpaceData.id,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setOpenSnackbar({ message: data.message, type: "success" });
                    const workspaces = JSON.parse(JSON.stringify(workspaceContext.workspaceList));
                    const findWorkspace = workspaces.find((workspace) => workspace.id === workSpaceData.id);
                    findWorkspace.name = workspaceName;
                    workspaceContext.handleWorkspaceList(workspaces);
                } else {
                    setWorkspaceName(workSpaceData.name);
                    setOpenSnackbar({ message: data.message, type: "error" });
                }
                setWorkspaceLoading(false);
            });

        setWorkspaceLoading(false);
        setIsEditable(false);
    };
    const handleEditClick = () => setIsEditable(true);
    const handleInvite = () => {
        setIsLoading(true);
        const newMember = { email: newMemberEmail, role: newMemberRole.toLowerCase() };
        const isEmailPresent = members.some((member) => member.email === newMemberEmail);
        if (isEmailPresent) {
            setOpenSnackbar({ message: "Email Already Exists", type: "error" });
            setNewMemberEmail("");
            setNewMemberRole("");
            setIsLoading(false);
            return;
        }
        if (members.length >= workspaceContext.workspaceLimit.maxTeam) {
            setOpenSnackbar({
                message: `Team invite cannot include more than ${workspaceContext.workspaceLimit.maxTeam} members`,
                type: "error",
            });
            setNewMemberEmail("");
            setNewMemberRole("");
            setIsLoading(false);
            return;
        }
        fetch(`${REACT_APP_SERVER_URL}/sendTeamInvite`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspacev1_id: workSpaceData.id,
                userEmail: Object.keys(ctx.user).length > 0 ? ctx.user.email : "",
                teams: [newMember],
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success && data.message === "Invite Sent Successfully") {
                    setOpenSnackbar({ message: data.message, type: "success" });

                    getTeamMembers();
                    workspaceContext.handleUpdateWorkspace();
                } else {
                    setOpenSnackbar({ message: data.message, type: "error" });
                }
                setIsLoading(false);
            });
        setNewMemberEmail("");
        setNewMemberRole("");
    };

    const handleDelete = (id) => {
        handleActionClose();
        fetch(`${REACT_APP_SERVER_URL}/removeTeamMember/${id}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "POST",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    getTeamMembers();
                    workspaceContext.handleUpdateWorkspace();
                    setOpenSnackbar({ message: "Delete SuccessFully", type: "success" });
                } else {
                    setOpenSnackbar({ message: "Delete Failed", type: "error" });
                }

                setOpenDialog(false);
            });
    };
    const resendHandler = (member) => {
        handleActionClose();
        const updateMember = { ...member };
        updateMember.status = "pending";
        fetch(`${REACT_APP_SERVER_URL}/sendTeamInvite`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspacev1_id: workSpaceData.id,
                userEmail: Object.keys(ctx.user).length > 0 ? ctx.user.email : "",
                teams: [updateMember],
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success && data.message === "Invite Sent Successfully") {
                    setOpenSnackbar({ message: data.message, type: "success" });
                    getTeamMembers();
                } else {
                    setOpenSnackbar({ message: data.message, type: "error" });
                }
            });
    };
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setNewMemberEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(value));
    };
    const handleBackClick = () => {
        navigate("/dashboard/setting?workspace=true");
        handleCloseWorkSpace();
    };
    const getTeamMembers = () => {
        fetch(`${REACT_APP_SERVER_URL}/fetchWorkspaceMembers/${workSpaceData.id}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.data) {
                    const sortedMembers = data.data.sort((a, b) => b.id - a.id);

                    setMembers(sortedMembers);
                    const findRole = data.data.filter((member) => member.email === ctx.user.email);

                    if (findRole[0].role === "member") {
                        setIsMember(true);
                    }

                    // const workspaces = JSON.parse(JSON.stringify(workspaceContext.workspaceList));
                    // const findWorkspace = workspaces.find((workspace) => workspace.id === workSpaceData.id);
                    // findWorkspace.totalMembers = data.data.length;
                    // workspaceContext.handleWorkspaceList(workspaces);
                }
                setShowSkeleton(false);
            });
    };
    useEffect(() => {
        getTeamMembers();
        setShowSkeleton(true);
    }, []);
    const handleReplaceRole = (member) => {
        setReplaceLoading(true);
        fetch(
            `${REACT_APP_SERVER_URL}/updateWorkspaceMemberRole`,

            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                method: "POST",
                body: JSON.stringify({
                    workspaceId: workSpaceData.id,
                    email: member.email,
                    roleId: member.role === "member" ? 2 : 3,
                }),
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((d) => {
                if (d.success) {
                    setOpenSnackbar({ message: "Role Changed SuccessFully", type: "success" });
                    workspaceContext.handleUpdateWorkspace();

                    getTeamMembers();
                } else {
                    setOpenSnackbar({ message: d.message, type: "error" });
                }
                setReplaceLoading(false);
                setOpenDialog(false);
            });
    };
    const handleRoleClick = (member) => {
        setOpenDialog(true);
        setModalData({
            content: `Are you sure to replace role to ${member.role === "owner" ? "member" : "owner"}`,
            buttonText: "Reset",
            clickFunction: function () {
                handleReplaceRole(member);
            },
        });
    };
    return (
        <>
            <Box>
                <Box sx={{ padding: 4 }}>
                    <ArrowBackIcon
                        onClick={handleBackClick}
                        sx={{
                            marginBottom: 2,
                            cursor: "pointer",
                            "&:hover": {
                                color: "blue",
                            },
                        }}
                    />

                    <Paper elevation={1} sx={{ padding: 3, mb: 3 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Workspace
                        </Typography>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item md={12} sm={8}>
                                <Tooltip title={isMember ? "This workspace is not editable" : ""}>
                                    <TextField
                                        onClick={handleEditClick}
                                        fullWidth
                                        label={isMember ? undefined : "Workspace Name"}
                                        value={workspaceName}
                                        onChange={(e) => setWorkspaceName(e.target.value)}
                                        variant="outlined"
                                        disabled={isMember}
                                        sx={
                                            isMember && {
                                                backgroundColor: "#d9d9d9", // Set background color
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#ccc", // Set border color to match background
                                                    },
                                                },
                                            }
                                        }
                                        InputProps={{ readOnly: !isEditable || isMember }}
                                        error={workspaceName && workspaceName.trim().length < 5}
                                        helperText={
                                            workspaceName && workspaceName.trim().length < 5
                                                ? "name must be at least 5 characters long"
                                                : ""
                                        }
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Paper>
                    {showSkeleton ? (
                        <Skeleton variant="rectangular" width="100%" height={80} sx={{ marginBottom: "20px" }} />
                    ) : (
                        <>
                            {!isMember && (
                                <Paper elevation={1} sx={{ padding: 3, mb: 3 }}>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        Add Member
                                    </Typography>
                                    <Grid container alignItems="flex-start" spacing={3}>
                                        <Grid item xs={12} sm={8} md={8}>
                                            <TextField
                                                fullWidth
                                                label="Enter email address"
                                                value={newMemberEmail}
                                                onChange={handleEmailChange}
                                                variant="outlined"
                                                error={!isValidEmail && !!newMemberEmail}
                                                helperText={
                                                    !isValidEmail && newMemberEmail ? "Enter a valid email address" : ""
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel>Select Role</InputLabel>
                                                <Select
                                                    name="role"
                                                    label="Select Role"
                                                    value={newMemberRole}
                                                    onChange={(e) => setNewMemberRole(e.target.value)}
                                                    renderValue={(selected) => selected || ""}
                                                >
                                                    <MenuItem value="Owner">
                                                        <div>
                                                            <Typography variant="body1">Owner</Typography>
                                                            <Typography
                                                                variant="caption"
                                                                color="textSecondary"
                                                                style={{ display: "block" }}
                                                            >
                                                                Owners have access to the entire account and all
                                                                workspaces
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                    <MenuItem value="Member">
                                                        <div>
                                                            <Typography variant="body1">Member</Typography>
                                                            <Typography
                                                                variant="caption"
                                                                color="textSecondary"
                                                                style={{ display: "block" }}
                                                            >
                                                                Members only have access to this workspace
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={1}>
                                            <Button
                                                sx={{ marginTop: 1 }}
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={handleInvite}
                                                disabled={!newMemberEmail || !newMemberRole || !isValidEmail}
                                            >
                                                {isLoading ? <CircularProgress color="inherit" /> : "Invite"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )}
                        </>
                    )}
                    <Paper elevation={1} sx={{ padding: 3 }}>
                        <Typography variant="h5" sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                            <span>Members -</span>
                            <Typography component="span" sx={{ fontWeight: "bold", ml: 1 }}>
                                {`${members.length}`}
                            </Typography>
                            /
                            <Typography component="span" sx={{ fontWeight: "bold" }}>
                                {workspaceContext.workspaceLimit.maxTeam}
                            </Typography>
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        {showSkeleton ? (
                            <Skeleton variant="rectangular" width="100%" height={80} sx={{ marginBottom: "20px" }} />
                        ) : (
                            <>
                                {members.length === 0 ? (
                                    "No Team Members Found"
                                ) : (
                                    <>
                                        {members.map((member) => (
                                            <Grid
                                                key={member.email}
                                                container
                                                alignItems="center"
                                                spacing={2}
                                                sx={{ mb: 2 }}
                                            >
                                                <Grid item md={8} sm={4}>
                                                    <Typography variant="body1">{member.name}</Typography>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {member.email}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Chip
                                                                label={member.status === 1 ? "Active" : "Pending"}
                                                                size="small"
                                                                sx={{
                                                                    backgroundColor:
                                                                        member.status === 1
                                                                            ? "success.main"
                                                                            : "#ffd740",
                                                                    color: "white",
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={3} sm={3}>
                                                    <Select
                                                        fullWidth
                                                        labelId="role-label"
                                                        name="role"
                                                        value={member.role === "member" ? "Member" : "Owner"}
                                                        renderValue={(selected) => selected || ""}
                                                        disabled={
                                                            member.email === ctx.user.email ||
                                                            isMember ||
                                                            member.status !== 1
                                                        }
                                                    >
                                                        <MenuItem
                                                            value="Owner"
                                                            onClick={() => {
                                                                member.role !== "owner" && handleRoleClick(member);
                                                            }}
                                                        >
                                                            <div>
                                                                <Typography variant="body1">Owner</Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    color="textSecondary"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    Owners have access to the entire account and all
                                                                    workspaces
                                                                </Typography>
                                                            </div>
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="Member"
                                                            onClick={() => {
                                                                member.role !== "member" && handleRoleClick(member);
                                                            }}
                                                        >
                                                            <div>
                                                                <Typography variant="body1">Member</Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    color="textSecondary"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    Members only have access to this workspace
                                                                </Typography>
                                                            </div>
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item>
                                                    {member.email !== ctx.user.email && !isMember && (
                                                        <>
                                                            <MoreVertIcon
                                                                id="basic-button"
                                                                aria-controls={
                                                                    openAction && selectedMember?.email === member.email
                                                                        ? "basic-menu"
                                                                        : undefined
                                                                }
                                                                aria-haspopup="true"
                                                                aria-expanded={
                                                                    openAction && selectedMember?.email === member.email
                                                                        ? "true"
                                                                        : undefined
                                                                }
                                                                onClick={(event) => handleActionMenu(event, member)}
                                                                sx={{ cursor: "pointer", fontWeight: "bold" }}
                                                            />

                                                            {selectedMember?.email === member.email && (
                                                                <Menu
                                                                    id="basic-menu"
                                                                    anchorEl={actionMenu}
                                                                    open={openAction}
                                                                    onClose={handleActionClose}
                                                                    MenuListProps={{
                                                                        "aria-labelledby": "basic-button",
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setOpenDialog(true);
                                                                            setModalData({
                                                                                content: `Are you sure you want to delete ${member.email}?`,
                                                                                buttonText: "Delete",
                                                                                clickFunction: function () {
                                                                                    handleDelete(member.id);
                                                                                },
                                                                            });
                                                                            handleActionClose(); // Close the menu after action
                                                                        }}
                                                                        sx={{ cursor: "pointer", color: "red" }}
                                                                    >
                                                                        Delete
                                                                    </MenuItem>
                                                                    {member.status !== 1 && (
                                                                        <MenuItem
                                                                            onClick={() => resendHandler(member)}
                                                                            sx={{ cursor: "pointer", color: "green" }}
                                                                        >
                                                                            Resend
                                                                        </MenuItem>
                                                                    )}
                                                                </Menu>
                                                            )}
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </Paper>
                    {openDialog && (
                        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                            <DialogContent>
                                <DialogContentText>{modalData.content}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{ cursor: "pointer" }} color="error" onClick={() => setOpenDialog(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        modalData.clickFunction();
                                    }}
                                >
                                    {replaceLoading ? <CircularProgress color="inherit" /> : modalData.buttonText}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    <SnackBar
                        open={openSnackbar}
                        message={openSnackbar.message}
                        type={openSnackbar.type}
                        onClose={() => setOpenSnackbar(false)}
                    />
                </Box>
            </Box>
            {isEditable && !isMember && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: "16px",
                        borderTop: "1px solid #e0e0e0",
                        backgroundColor: "#fff",
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        left: 0,
                    }}
                >
                    <Box>
                        <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleSaveClick}>
                            {workspaceLoading ? <CircularProgress color="inherit" /> : "Save"}
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleCancelClick}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default WorkspaceManagement;
