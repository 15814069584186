import React, { useContext, useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import logo from "../img/icons/xaudit1.png";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "./Provider/AuthProvider";
import cookie from "cookiejs";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
const VerificationEmailSent = () => {
    const navigate = useNavigate();
    const ctx = useContext(AuthContext);
    const token = cookie.get("token");
    const decode = token ? ctx.user : false;
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const email = queryParams.get("email");
    useEffect(() => {
        if (decode && decode.email === email) {
            if (email === "rakesh@saleshive.com") {
                console.log("lies in rakesh");
                navigate("/dashboard/home");
            }
            console.log("outside rakesh");
            navigate("/dashboard/home");
        }
    }, []);

    return (
        <Box>
            <Box
                component="img"
                src={logo} // Replace with your image link
                alt="Placeholder"
                sx={{ marginBottom: 2, width: "15%" }}
            />
            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <MailOutlineIcon sx={{ minWidth: "80px", height: "auto" }} />
                <Typography sx={{ fontSize: "30px", fontWeight: "700" }}>Please verify your email!</Typography>

                <Typography variant="body1" color="textSecondary">
                    A confirmation mail was sent to <strong>{email ? email : "No email Found"}</strong>
                </Typography>

                <Typography variant="body2" color="textSecondary" mt={2}>
                    You're almost there!
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: "10px" }}>
                    To activate your account, click the link in the email we sent you.
                </Typography>

                <hr style={{ border: "1px solid #ccc", width: "100%" }} />
                <Typography variant="body2" color="textSecondary" sx={{ paddingTop: "10px" }}>
                    Check your spam folder if you do not see it.
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        marginTop: "20px",
                        textAlign: "center",
                        paddingTop: "10px",
                    }}
                >
                    If you have any questions or need assistance, feel free to contact our support team at
                    support@xemailaudit.com.
                </Typography>
            </Container>
        </Box>
    );
};

export default VerificationEmailSent;
